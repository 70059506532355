//componentes
import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  InputGroup,
  Form,
  Spinner,
} from "react-bootstrap";
import AcademicCard from "../../components/AcademicCard";
import HorizontalBlueLine from "../../components/horizontalBlueLine";
import AcademicLastCard from "../../components/AcademicLastCard";

//estilos e iconos
import "./styles.css";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//config y utilidades
import OrdenarAcademicos from "./../../utilities/OrdenarAcademicos";
import { REST_URL, REST_URL_IMAGES } from "../../config/env";

export default function Academicos({
  tipoURL = new URLSearchParams(window.location.search).get("tipo"),
}) {
  const [academicos, setAcademicos] = useState([]);
  const [academicosCopy, setAcademicosCopy] = useState([]);
  const [loading, setloading] = useState(true);
  const [last, setLast] = useState([]);
  const [urlAcademicos, seturlAcademicos] = useState();
  let [totalNumero, settotalNumero] = useState([0]);
  let [totaldelegado, settotaldelegado] = useState([0]);
  let [totalcorrespondientes, settotalcorrespondientes] = useState([0]);
  let [totalemeritos, settotalemeritos] = useState([0]);
  let [totalhonorios, settotalhonorios] = useState([0]);
  let [totalfallecidos, settotalfallecidos] = useState([0]);
  useEffect(() => {
    setloading(true);
    fetch(REST_URL + "/academicos", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((rawJson) => {
        let academicosArrayEtiquetas = [];
        let i = -1;

        //Recorre todos los academicos.
        rawJson.map((academico) => {
          i++;
          //contar tipo de academico.
          if (academico.tipo === "Académicos de número") {
            settotalNumero((totalNumero) => parseInt(totalNumero) + 1);
          }
          if (academico.tipo === "Académicos delegados") {
            settotaldelegado((totaldelegado) => parseInt(totaldelegado) + 1);
          }
          if (academico.tipo === "Académicos correspondientes") {
            settotalcorrespondientes(
              (totaltotalcorrespondientes) =>
                parseInt(totaltotalcorrespondientes) + 1
            );
          }
          if (academico.tipo === "Académicos Eméritos") {
            settotalemeritos(
              (totaltotalemeritos) => parseInt(totaltotalemeritos) + 1
            );
          }
          if (academico.tipo === "Académicos delegados honorios") {
            settotalhonorios((totalhonorios) => parseInt(totalhonorios) + 1);
          }
          if (academico.tipo === "Académicos fallecidos") {
            settotalfallecidos(
              (totalfallecidos) => parseInt(totalfallecidos) + 1
            );
          }

          //guarda en el array todos los academicos.
          return (academicosArrayEtiquetas[i] = (
            <AcademicCard
              id={academico.id}
              orden={academico.orden}
              key={i}
              Nombre={academico.nombre}
              Apellido=""
              Localizacion={academico.pais || academico.provincia}
              Profecion={academico.disciplina}
              tipo={academico.tipo}
              Img={REST_URL_IMAGES + "/academicos_imagenes/" + academico.imagen}
            />
          ));
        });
        academicosArrayEtiquetas.sort(OrdenarAcademicos);
        setAcademicosCopy(academicosArrayEtiquetas);

        //cargar dinamicamente los academicos por url.
        const ultimoAcademico = localStorage.getItem("ultimoAcademico");

        const URL_PARAM_TIPO = tipoURL.replace(/"/g, "");

        if (ultimoAcademico !== null) {
          seturlAcademicos("/ " + ultimoAcademico);

          let arrayAux = [];

          academicosArrayEtiquetas.map((academicos) => {
            if (academicos.props.tipo === ultimoAcademico) {
              return arrayAux.push(academicos);
            }
            return 0;
          });

          setAcademicos(arrayAux);
        } else {
          seturlAcademicos("/ " + URL_PARAM_TIPO);

          let arrayAux = [];

          academicosArrayEtiquetas.map((academicos) => {
            if (academicos.props.tipo === URL_PARAM_TIPO) {
              return arrayAux.push(academicos);
            }
            return 0;
          });
          setAcademicos(arrayAux);
        }
        setloading(false);
      });
    cargarUltimos3();
  }, []);

  async function cargarUltimos3() {
    await fetch(REST_URL + "/last")
      .then((response) => response.json())
      .then((rawJson) => {
        let lastAcademicos = [];
        let j = -1;

        rawJson.map((lastAcademico) => {
          j++;
          return (lastAcademicos[j] = (
            <AcademicLastCard
              key={j}
              titulo={lastAcademico.nombre}
              tipo={lastAcademico.tipo}
              link={
                "/academicos/" + lastAcademico.id + "?id=" + lastAcademico.id
              }
              img={
                REST_URL_IMAGES + "/academicos_imagenes/" + lastAcademico.imagen
              }
            />
          ));
        });

        setLast(lastAcademicos);
      });
  }

  //buscador.

  const handlebuscarAcademicos = (event) => {
    let valorBusqueda = event.target.value;
    const regex = new RegExp(valorBusqueda, "i");

    let academicosTotales = academicosCopy;
    let arrayAux = [];

    academicosTotales.map((academico) => {
      if (regex.test(academico.props.Nombre)) {
        return arrayAux.push(academico);
      }
      if (regex.test(academico.props.Profecion)) {
        return arrayAux.push(academico);
      }

      return 0;
    });

    setAcademicos(arrayAux);
  };

  //filtros
  function handleClickAll() {
    setAcademicos(academicosCopy);
    seturlAcademicos("");
  }

  function handleClickNumeros() {
    let totalAcademicos = academicosCopy;
    let arrayAux = [];

    for (let i = 0; i < totalAcademicos.length; i++) {
      if (totalAcademicos[i].props.tipo === "Académicos de número") {
        arrayAux.push(totalAcademicos[i]);
      }
    }
    arrayAux.sort(OrdenarAcademicos);
    setAcademicos(arrayAux);
    seturlAcademicos("/ Académicos de número");
  }

  function handleClickDelegados() {
    let totalAcademicos = academicosCopy;
    let arrayAux = [];

    for (let i = 0; i < totalAcademicos.length; i++) {
      if (totalAcademicos[i].props.tipo === "Académicos delegados") {
        arrayAux.push(totalAcademicos[i]);
      }
    }
    arrayAux.sort(OrdenarAcademicos);
    setAcademicos(arrayAux);
    seturlAcademicos("/ Académicos delegados");
  }

  function handleClickCorrespondiente() {
    let totalAcademicos = academicosCopy;
    let arrayAux = [];

    for (let i = 0; i < totalAcademicos.length; i++) {
      if (totalAcademicos[i].props.tipo === "Académicos correspondientes") {
        arrayAux.push(totalAcademicos[i]);
      }
    }
    arrayAux.sort(OrdenarAcademicos);
    setAcademicos(arrayAux);
    seturlAcademicos("/ Académicos correspondientes");
  }

  function handleClickEmeritos() {
    let totalAcademicos = academicosCopy;
    let arrayAux = [];

    for (let i = 0; i < totalAcademicos.length; i++) {
      if (totalAcademicos[i].props.tipo === "Académicos Eméritos") {
        arrayAux.push(totalAcademicos[i]);
      }
    }
    arrayAux.sort(OrdenarAcademicos);
    setAcademicos(arrayAux);
    seturlAcademicos("/ Académicos eméritos");
  }

  function handleClickhonorios() {
    let totalAcademicos = academicosCopy;
    let arrayAux = [];

    for (let i = 0; i < totalAcademicos.length; i++) {
      if (totalAcademicos[i].props.tipo === "Académicos delegados honorios") {
        arrayAux.push(totalAcademicos[i]);
      }
    }
    arrayAux.sort(OrdenarAcademicos);
    setAcademicos(arrayAux);
    seturlAcademicos("/ Académicos delegados honorarios");
  }

  function handleClickFallecidos() {
    let totalAcademicos = academicosCopy;
    let arrayAux = [];

    for (let i = 0; i < totalAcademicos.length; i++) {
      if (totalAcademicos[i].props.tipo === "Académicos fallecidos") {
        arrayAux.push(totalAcademicos[i]);
      }
    }
    arrayAux.sort(OrdenarAcademicos);
    setAcademicos(arrayAux);
    seturlAcademicos("/ Académicos fallecidos");
  }

  return (
    <section id="academicos">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/FrenteAnba.png")}
          alt="frente ANBA"
        />
        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloAcademicos">
            ACADÉMICOS
          </h3>
        </div>
      </article>
      <div className="d-md-block">
        <Container className="py-3">
          <Row className="justify-content-center justify-content-lg-between">
            <p className="d-none d-md-block pathTitle">
              <span>Inicio /</span> <span>Académicos {urlAcademicos}</span>
            </p>

            {/* Grid de academicos */}
            {loading ? (
              <Col className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Col>
            ) : (
              <Col
                md="6"
                xl="8"
                className="me-xl-4 pe-xl-3 py-4 py-xl-5 d-none d-md-block"
              >
                <div className="CardsConteiner">{academicos}</div>
              </Col>
            )}

            {/* Menu Lateral */}
            <Col className="menuLateral">
              <h5 className="TituloMenuLateral">BUSCAR</h5>
              <HorizontalBlueLine />

              <InputGroup className="searchConteiner mb-3">
                <Form.Control
                  placeholder="Buscar..."
                  aria-describedby="basic-addon2"
                  onChange={handlebuscarAcademicos}
                />
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="iconSearch"
                />
              </InputGroup>
              <h5 className="TituloMenuLateral">ACADÉMICOS</h5>
              <HorizontalBlueLine />

              <div className="ListaAcademicos">
                <div className="tipoAcademico" onClick={handleClickAll}>
                  <p className="TituloAcademicos">
                    <span>Todos</span>
                  </p>
                </div>

                <div className="DividerAcademico"></div>

                <div className="tipoAcademico" onClick={handleClickNumeros}>
                  <p className="TituloAcademicos">
                    <span>Académicos de número</span>
                  </p>
                  <p className="numero">({totalNumero})</p>
                </div>

                <div className="DividerAcademico"></div>

                <div className="tipoAcademico" onClick={handleClickDelegados}>
                  <p className="TituloAcademicos">
                    <span>Académicos delegados</span>
                  </p>
                  <p className="numero">({totaldelegado})</p>
                </div>

                <div className="DividerAcademico"></div>

                <div
                  className="tipoAcademico"
                  onClick={handleClickCorrespondiente}
                >
                  <p className="TituloAcademicos">
                    <span>Académicos correspondientes</span>
                  </p>
                  <p className="numero">({totalcorrespondientes})</p>
                </div>

                <div className="DividerAcademico"></div>

                <div className="tipoAcademico" onClick={handleClickEmeritos}>
                  <p className="TituloAcademicos">
                    <span>Académicos eméritos</span>
                  </p>
                  <p className="numero">({totalemeritos})</p>
                </div>
                <div className="DividerAcademico"></div>

                <div className="tipoAcademico" onClick={handleClickhonorios}>
                  <p className="TituloAcademicos">
                    <span>Académicos delegados honorarios</span>
                  </p>
                  <p className="numero">({totalhonorios})</p>
                </div>

                <div className="DividerAcademico"></div>

                <div className="tipoAcademico" onClick={handleClickFallecidos}>
                  <p className="TituloAcademicos">
                    <span>Académicos fallecidos</span>
                  </p>
                  <p className="numero">({totalfallecidos})</p>
                </div>

                <div className="DividerAcademico"></div>
              </div>

              <h5 className="d-none d-md-block TituloMenuLateral">
                ÚLTIMOS ACADÉMICOS INCORPORADOS
              </h5>
              <HorizontalBlueLine />

              <div className="d-none d-md-block AcademicosIncorporadosConteiner">
                {last}
                <div className="DividerAcademico"></div>
              </div>
            </Col>

            <Col
              md="6"
              xl="8"
              className="me-xl-4 pe-xl-3 py-4 py-xl-5 d-block d-md-none"
            >
              <div className="CardsConteiner">{academicos}</div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
