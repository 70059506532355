import "./styles.css";
import { Card } from "react-bootstrap";
import bg from './../../assets/img/Bg.png'
import { Link } from "react-router-dom";

export default function AcademicCard({ Nombre = "Nombre",
    Apellido = "Apellidos",
    Localizacion,
    Profecion = "Profesion",
    tipo = "tipo Academico",
    id = 0,
    Img = bg,
}) {
    return (
        <Link className="AcademicoLink" to={'/academicos/' + id + '?id=' + id} onClick={() => { window.scroll(0, 0); }}>
            <Card className="Carta">
                <div className="Cardwrapper">
                    <img
                        className="CardImg"
                        alt="Sample"
                        src={Img}
                    />
                </div>
                <div>
                    <div className="CardDivider"></div>
                    <h2 className="CartaTitulo" id="nombre">
                        {Nombre}
                    </h2>
                    <h2 className="CartaTitulo">
                        {Apellido}
                    </h2>
                    <span className="CartaProfesion">
                        {Profecion}
                    </span>
                    {Localizacion && (tipo == "Académicos correspondientes"
                        || tipo == "Académicos delegados"
                        || tipo == "Académicos delegados honorios"
                        || tipo == "Académicos fallecidos") &&
                        <div>
                            <div className="CardDividerFooter"></div>
                            <p className="CartaAcademico">
                                {Localizacion}
                            </p>
                        </div>
                    }
                </div>
            </Card >
        </Link>
    )

}
