import React, { useState } from "react";
import "./styles.css";
import { Container } from "react-bootstrap";
import dot from "./../../assets/img/hitos/dot.png";
import ImgPopup from "../../components/imgPopup";
//fotos
import p22 from "../../assets/img/hitos/22.jpg";
import p21 from "../../assets/img/hitos/21.jpeg";
import p20 from "../../assets/img/hitos/20.jpg";
import p19 from "../../assets/img/hitos/19.jpg";
import p18 from "../../assets/img/hitos/18.jpg";
import p17 from "../../assets/img/hitos/17.jpg";
import p16 from "../../assets/img/hitos/16.jpg";
import p15 from "../../assets/img/hitos/15.jpg";
import p14 from "../../assets/img/hitos/14.jpg";
import p13 from "../../assets/img/hitos/13.jpg";
import p12 from "../../assets/img/hitos/12.jpg";
import p11 from "../../assets/img/hitos/11.jpg";
import p10 from "../../assets/img/hitos/10.jpg";
import p9 from "../../assets/img/hitos/9.jpg";
import p8 from "../../assets/img/hitos/8.jpg";
import p7 from "../../assets/img/hitos/7.jpg";
import p5 from "../../assets/img/hitos/5.jpg";
import p4 from "../../assets/img/hitos/4.jpg";
import p3 from "../../assets/img/hitos/3.jpg";
import p2 from "../../assets/img/hitos/2.jpg";
import p1 from "../../assets/img/hitos/1.jpg";

export default function Hitos() {
  const [popupOpen, setPopupOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  function openPopup(src) {
    setCurrentPhotoIndex(src);
    setPopupOpen(true);
    console.log(src);
  }

  function closePopup() {
    setPopupOpen(false);
  }

  return (
    <section id="Hitos">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/Bg_hitos.png")}
          alt="Sala de plenario"
        />
        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            Línea de tiempo
          </h3>
        </div>
      </article>
      <div className="d-md-block">
        <Container className="py-3">
          {popupOpen && (
            <ImgPopup
              src={currentPhotoIndex}
              alt="Descripción de la imagen"
              onClose={closePopup}
            />
          )}

          <div className="TimeLineContainer">

          <div className="timeLineBlockRight">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageRight">
                  {" "}
                  El Director de la Real Academia con Matilde Marin
                </p>
                <img
                  className="timeLineImage"
                  src={p22}
                  alt="aniversario 80 años de la ANBA"
                  onClick={() => openPopup(p22)}
                />
              </div>

              <div className="timeLineDivider">
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainer">
                <p className="TimeLineYear">2024</p>
                <p className="TimeLineText">
                  El músico Tomas Moro, Director de la Real Academia de Bellas Artes de San Fernando y la artista Matilde Marin, Presidenta de la Academia Nacional de Bellas Artes, Madrid 2024
                </p>
              </div>
            </div>

            <div className="timeLineBlockLeft">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageLeft">
                  Donación al Fondo Patrimonial de la Academia Nacional de
                  Bellas Artes
                </p>
                <img
                  className="timeLineImage"
                  src={p20}
                  alt="aniversario 80 años de la ANBA"
                  onClick={() => openPopup(p20)}
                />
              </div>

              <div className="timeLineDivider">
                <div className="TimeLineLineUp"></div>
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainerRight">
                <p className="TimeLineYear">2022</p>
                <p className="TimeLineText">
                  Donación al Fondo Patrimonial de la Academia Nacional de
                  Bellas Artes
                </p>
                <p className="TimeLineText">
                  {" "}
                  la colección “Ex Libris” por parte de Bernardo Lozier Almazán,
                  2022.
                </p>
              </div>
            </div>

            <div className="timeLineBlockRight">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageRight">
                  {" "}
                  Exposición “Mujeres grabadoras”
                </p>
                <img
                  className="timeLineImage"
                  src={p19}
                  alt="aniversario 80 años de la ANBA"
                  onClick={() => openPopup(p19)}
                />
              </div>

              <div className="timeLineDivider">
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainer">
                <p className="TimeLineYear">2019</p>
                <p className="TimeLineText">
                  Exposición "Mujeres grabadoras" en Bellas Artes del Ministerio
                  de Educación de la Nación, 2019.
                </p>
              </div>
            </div>

            <div className="timeLineBlockLeft">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageLeft">
                  {" "}
                  Visita a la Real Academia de Bellas Artes de Barcelona”
                </p>
                <img
                  className="timeLineImage"
                  src={p21}
                  alt="Visita a la Real Academia de Bellas Artes de Barcelona"
                  onClick={() => openPopup(p21)}
                />
              </div>

              <div className="timeLineDivider">
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainer">
                <p className="TimeLineYear">2019</p>
                <p className="TimeLineText">
                  El Maestro Guillermo Scarabino de visita a la Real Academia de Bellas Artes San Jorge de Barcelona junto a su presidente Joan Antoni Solans i Huguet.
                </p>
              </div>
            </div>

            <div className="timeLineBlockRight">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageRight">
                  Exposición perteneciente al legado de Alfredo González Garaño
                  y Marietta Ayerza
                </p>
                <img
                  className="timeLineImage"
                  src={p18}
                  alt="aniversario 80 años de la ANBA"
                  onClick={() => openPopup(p18)}
                />
              </div>

              <div className="timeLineDivider">
                <div className="TimeLineLineUp"></div>
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainerRight">
                <p className="TimeLineYear">2016</p>
                <p className="TimeLineText">
                  Exposición de dibujos y pinturas pertenecientes al legado de
                  Alfredo González
                </p>
                <p className="TimeLineText">
                  Garaño y Marietta Ayerza, fondo Patrimonial de la Academia
                  Nacional
                </p>
                <p className="TimeLineText">
                  de Bellas Artes, exhibido en el Museo Nacional de Arte
                  Decorativo, 2016.
                </p>
              </div>
            </div>

            <div className="timeLineBlockLeft">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageLeft">
                  Acto por los 80 años de la Academia Nacional de Bellas Artes
                </p>
                <img
                  className="timeLineImage"
                  alt="aniversario 80 años de la ANBA"
                  src={p17}
                  onClick={() => openPopup(p17)}
                />
              </div>

              <div className="timeLineDivider">
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainer">
                <p className="TimeLineYear">2016</p>
                <p className="TimeLineText">
                  Acto por los 80 años de la Academia Nacional de Bellas Artes.
                </p>
              </div>
            </div>

            <div className="timeLineBlockRight">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageRight">
                  Conferencia del Académico Correspondiente Tomás Maldonado
                </p>
                <img
                  className="timeLineImage"
                  alt="aniversario 80 años de la ANBA"
                  src={p16}
                  onClick={() => openPopup(p16)}
                />
              </div>

              <div className="timeLineDivider">
                <div className="TimeLineLineUp"></div>
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainerRight">
                <p className="TimeLineYear">2014</p>
                <p className="TimeLineText">
                  Conferencia del Académico Correspondiente Tomás Maldonado
                </p>
                <p className="TimeLineText">
                  organizada por la Academia Nacional de Bellas Artes en el{" "}
                </p>
                <p className="TimeLineText">
                  auditorio de la Academia Nacional de Medicina, 2014.
                </p>
              </div>
            </div>

            <div className="timeLineBlockLeft">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageLeft">
                  La vuelta al país en 80 grabadores{" "}
                </p>
                <img
                  className="timeLineImage"
                  alt="aniversario 80 años de la ANBA"
                  src={p15}
                  onClick={() => openPopup(p15)}
                />
              </div>

              <div className="timeLineDivider">
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainer">
                <p className="TimeLineYear">2011</p>
                <p className="TimeLineText">
                  La vuelta al país en 80 grabadores - Segunda parte, exposición{" "}
                </p>
                <p className="TimeLineText">
                  itinerante curaduría del Dr. Jorge Taberna Irigoyen,{" "}
                </p>
                <p className="TimeLineText">
                  presidente de la Academia Nacional de Bellas Artes, 2011.
                </p>
              </div>
            </div>

            <div className="timeLineBlockRight">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageRight">
                  {" "}
                  Incorporación de Daniel Baremboin{" "}
                </p>
                <img
                  className="timeLineImage"
                  alt="aniversario 80 años de la ANBA"
                  src={p14}
                  onClick={() => openPopup(p14)}
                />
              </div>

              <div className="timeLineDivider">
                <div className="TimeLineLineUp"></div>
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainerRight">
                <p className="TimeLineYear">2008</p>
                <p className="TimeLineText">
                  {" "}
                  Incorporación de Daniel Baremboin como Académico{" "}
                </p>
                <p className="TimeLineText">
                  Correspondiente junto al entonces Presidente de la{" "}
                </p>
                <p className="TimeLineText">
                  {" "}
                  Academia Nacional de Bellas Artes Jorge Taverna Irigoyen,
                  2008.
                </p>
              </div>
            </div>

            <div className="timeLineBlockLeft">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageLeft">
                  Nelly Perazo con los artistas Alejandro Puente y Matilde Marín
                </p>
                <img
                  className="timeLineImage"
                  src={p13}
                  onClick={() => openPopup(p13)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainer">
                <p className="TimeLineYear">1999</p>
                <p className="TimeLineText">
                  Nelly Perazo, Presidenta de la Academia Nacional de Bellas
                  Artes,
                </p>
                <p className="TimeLineText">
                  con los artistas Alejandro Puente y Matilde Marín, 1999.
                </p>
              </div>
            </div>

            <div className="timeLineBlockRight">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageRight">
                  Publicación del primer número de la revista TEMAS
                </p>
                <img
                  className="timeLineImage"
                  src={p12}
                  onClick={() => openPopup(p12)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <div className="TimeLineLineUp"></div>
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainerRight">
                <p className="TimeLineYear"> 1999</p>
                <p className="TimeLineText">
                  Publicación del primer número de la revista TEMAS
                </p>
                <p className="TimeLineText">
                  Temas de la Academia. Diseño: ¿Estética del siglo XX?.
                </p>
                <p className="TimeLineText">
                  Edición Academia Nacional de Bellas Artes. Curadores:
                </p>
                <p className="TimeLineText">
                  Rosa María Ravera y Ricardo Blanco. 1999.
                </p>
              </div>
            </div>

            <div className="timeLineBlockLeft">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageLeft">
                  Incorporación de la Fundación Federico Jorge Klem
                </p>
                <img
                  className="timeLineImage"
                  src={p11}
                  onClick={() => openPopup(p11)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainer">
                <p className="TimeLineYear">1995</p>
                <p className="TimeLineText">
                  Incorporación de la Fundación Federico Jorge Klem
                </p>
                <p className="TimeLineText">
                  {" "}
                  a la Academia Nacional de Bellas Artes por testamento
                </p>
                <p className="TimeLineText">
                  de su fundador Federico Jorge Klem, 1995.
                </p>
              </div>
            </div>

            <div className="timeLineBlockRight">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageRight">
                  Creación de la Fundación Alberto J. Trabucco
                </p>
                <img
                  className="timeLineImage"
                  src={p10}
                  onClick={() => openPopup(p10)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <div className="TimeLineLineUp"></div>
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainerRight">
                <p className="TimeLineYear">1991</p>
                <p className="TimeLineText">
                  Creación de la Fundación Alberto J. Trabucco
                </p>
                <p className="TimeLineText">
                  por la Academia Nacional de Bellas Artes con fondos
                  provenientes
                </p>
                <p className="TimeLineText">
                  del legado que el distinguido artista, Alberto Trabucco,
                </p>
                <p className="TimeLineText">donó a la institución, 1991.</p>
              </div>
            </div>

            <div className="timeLineBlockLeft">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageLeft">Sesión Académica</p>
                <img
                  className="timeLineImage"
                  src={p9}
                  onClick={() => openPopup(p9)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainer">
                <p className="TimeLineYear">1989</p>
                <p className="TimeLineText">
                  Sesión Académica en la sede actual de la Academia Nacional de
                  Bellas Artes.
                </p>
                <p className="TimeLineText">Fotografía Aldo Sessa, 1989.</p>
              </div>
            </div>

            <div className="timeLineBlockRight">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageRight">
                  Publicación del ANUARIO
                </p>
                <img
                  className="timeLineImage"
                  src={p8}
                  onClick={() => openPopup(p8)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <div className="TimeLineLineUp"></div>
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainerRight">
                <p className="TimeLineYear"> 1986</p>
                <p className="TimeLineText">
                  Publicación del ANUARIO 14 de la Academia Nacional de Bellas
                  Artes
                </p>
                <p className="TimeLineText">
                  Cincuentenario de su creación, 1936-1986, 1986.
                </p>
              </div>
            </div>

            <div className="timeLineBlockLeft">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageLeft">
                  Publicación del tomo de la Historia general Argentina
                </p>
                <img
                  className="timeLineImage"
                  src={p7}
                  onClick={() => openPopup(p7)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainer">
                <p className="TimeLineYear">1982</p>
                <p className="TimeLineText">
                  Publicación del primer tomo de la Historia general del arte en
                  la Argentina
                </p>
                <p className="TimeLineText">
                  Edición Academia Nacional de Bellas Artes, 1982.
                </p>
              </div>
            </div>

            <div className="timeLineBlockRight">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageRight">
                  Sesión Plenaria en el Salón Comedor del Palacio Errázuriz.
                </p>
                <img
                  className="timeLineImage"
                  src={p5}
                  onClick={() => openPopup(p5)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <div className="TimeLineLineUp"></div>
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainerRight">
                <p className="TimeLineYear">1965</p>
                <p className="TimeLineText">
                  {" "}
                  Sesión Plenaria en el Salón Comedor del Palacio Errázuriz.{" "}
                </p>
                <p className="TimeLineText">
                  {" "}
                  Académicos presentes:Alfredo González Garaño,{" "}
                </p>
                <p className="TimeLineText">
                  {" "}
                  Horacio Butler,Ricardo Braun Menéndez, Emilio Centurión,
                </p>
                <p className="TimeLineText">
                  Alberto Ginastera, Carlos de la Cárcova,
                </p>
                <p className="TimeLineText">
                  {" "}
                  José Fioravanti, Héctor Basaldúa y Manuel
                </p>
                <p className="TimeLineText">Mujica Laínez, 1965.</p>
              </div>
            </div>

            <div className="timeLineBlockLeft">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageLeft">
                  Entrega del Premio Mozarteum Argentino
                </p>
                <img
                  className="timeLineImage"
                  src={p4}
                  onClick={() => openPopup(p4)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainer">
                <p className="TimeLineYear">1962</p>
                <p className="TimeLineText">
                  Entrega del Premio Mozarteum Argentino.
                </p>
                <p className="TimeLineText">
                  Raquel Forner, Alberto Ginastera y Jeannette Arata de Erize,
                  1962.
                </p>
              </div>
            </div>

            <div className="timeLineBlockRight">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageRight">
                  Creación de la Biblioteca Horacio Butler
                </p>
                <img
                  className="timeLineImage"
                  src={p3}
                  onClick={() => openPopup(p3)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <div className="TimeLineLineUp"></div>
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainerRight">
                <p className="TimeLineYear">1940</p>
                <p className="TimeLineText">
                  {" "}
                  Creación de la Biblioteca Horacio Butler a partir de la{" "}
                </p>
                <p className="TimeLineText">
                  donación de la biblioteca personal del artista Horacio Butler,
                  1940.
                </p>
              </div>
            </div>

            <div className="timeLineBlockLeft">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageLeft">Palacio Errázuriz</p>
                <img
                  className="timeLineImage"
                  src={p2}
                  onClick={() => openPopup(p2)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainer">
                <p className="TimeLineYear">1937</p>
                <p className="TimeLineText">
                  Palacio Errázuriz, obra del arquitecto René Sergent,
                </p>
                <p className="TimeLineText">
                  para sede de la Comisión Nacional de Cultura,
                </p>
                <p className="TimeLineText">
                  {" "}
                  la Academia Argentina de Letras, la Academia Nacional de
                  Bellas Artes y
                </p>
                <p className="TimeLineText">
                  el Museo Nacional de Arte Decorativo, 1937
                </p>
              </div>
            </div>

            <div className="timeLineBlockRight">
              <div className="TimeLineImgContainer">
                <p className="TimelineTitleimageRight">
                  Primera acta de la anba
                </p>
                <img
                  className="timeLineImage"
                  src={p1}
                  onClick={() => openPopup(p1)}
                  alt="aniversario 80 años de la ANBA"
                />
              </div>

              <div className="timeLineDivider">
                <div className="TimeLineLineUp"></div>
                <img className="TimeLineDot" src={dot} alt="dot" />
                <div className="TimeLineLine"></div>
              </div>

              <div className="TimeLineTextContainerRight">
                <p className="TimeLineYear">1936</p>
                <p className="TimeLineText">
                  Primera acta de la Academia Nacional De Bellas Artes.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
