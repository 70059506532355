import React from "react";
import "./styles.css";

//componentes
import { Container, Row, Col, Button } from "react-bootstrap";
import IconDivisor from "../../components/IconDivisor";
import DirectiveCard from "../../components/DirectiveCard";
import StaffCard from "../../components/StaffCard";
import { Link } from "react-router-dom";
import MapComponent from "../../utilities/MapComponent";

//imagenes e iconos
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import MapComponent from './../../assets/img/mapa.png'
import directivoPhoto1 from "./../../assets/img/Directivos/1.png";
import directivoPhoto2 from "./../../assets/img/Directivos/2.png";
import directivoPhoto3 from "./../../assets/img/Directivos/3.png";
import directivoPhoto4 from "./../../assets/img/Directivos/4.png";
import directivoPhoto5 from "./../../assets/img/Directivos/5.png";
import directivoPhoto6 from "./../../assets/img/Directivos/6.png";
import staffPhoto1 from "./../../assets/img/staff/1.png";
import staffPhoto2 from "./../../assets/img/staff/2.png";
import staffPhoto3 from "./../../assets/img/staff/3.png";
import staffPhoto4 from "./../../assets/img/staff/4.png";
import staffPhoto5 from "./../../assets/img/staff/5.png";
import staffPhoto6 from "./../../assets/img/staff/6.png";
import staffPhoto7 from "./../../assets/img/staff/7.png";
import staffPhoto8 from "./../../assets/img/staff/8.png";

export default function Institucional() {
  return (
    <section id="institucional">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/Plenario.jpg")}
          alt="Sala de plenario"
          id="plenario"
        />
        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            INSTITUCIONAL
          </h3>
        </div>
      </article>

      <div className="d-md-block">
        <Container className="py-5">
          <Row className="justify-content-center justify-content-lg-between">
            <Col>
              <h3 className="preTitulo article-subtitle mb-1">
                Objetivos de la Academia
              </h3>
              <h2 className="title-article text-center mb-0">
                ACADEMIA NACIONAL DE BELLAS ARTES
              </h2>
              <div className="py-3 text-center">
                <IconDivisor opacity={0.3} width={100} />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center justify-content-center">
            <Col xl="5" className="me-xl-4 pe-xl-3 py-4 py-xl-5">
              <h5 className="TituloLista">SUS OBJETIVOS SON:</h5>
              <ul className="listaObjetivos">
                <li>
                  Contribuir al desarrollo de las Bellas Artes en todas sus
                  ramas: artes visuales, música, arquitectura y urbanismo,
                  historia y crítica del arte y acción cultural
                </li>
                <li>
                  Realizar estudios e investigaciones divulgando sus resultados
                  de Patrimonio Artístico
                </li>
                <li>
                  Promover la conservación y el acrecimiento de los bienes de
                  propiedad pública o privada, que forman el patrimonio
                  artístico de la comunidad
                </li>
                <li>Formular planes de acción cultural</li>
                <li>
                  Asesorar al Gobierno de la Nación, a los gobiernos
                  provinciales, municipalidades, y a otras instituciones
                  culturales públicas o privadas
                </li>
                <li>Proponer proyectos de leyes y reglamentos</li>
                <li>
                  Apoyar toda manifestación relacionada con sus actividades
                  específicas.
                </li>
              </ul>
            </Col>
            <Col xl="5" className="me-xl-4 pe-xl-3 py-1 py-xl-5">
              <h5 className="TituloLista">COMISIONES ACADÉMICAS:</h5>
              <ul className="listaObjetivos">
                <li>Acción cultural</li>
                <li>
                Artes Visuales (cinematografía, dibujo, escultura, fotografía, grabado, pintura, textil y otros soportes)
                </li>
                <li>
                Arquitectura, Diseño y Urbanismo
                </li>
                <li>Música</li>
                <li>Historia y Crítica del Arte</li>
                <li>Archivo, Biblioteca, Investigación y Documentación del patrimonio artístico</li>
                <li>Publicaciones y Conferencias</li>
                <li>Interpretación y Reglamento</li>
                <li>Acción Cultural</li>
                <li>Recursos financieros</li>
              </ul>
            </Col>
          </Row>
          <Row
            className="justify-content-center justify-content-lg-between"
            id="MesaDirectiva"
          >
            <Col>
              <h2 className="title-article text-center mb-0">
                MESA DIRECTIVA 2022-2024
              </h2>
              <div className="py-3 text-center">
                <IconDivisor opacity={0.3} width={100} />
              </div>
            </Col>
          </Row>

          <div className="DirectiveCardConteiner">
            <DirectiveCard
              Nombre="MATILDE MARÍN"
              profesion="Presidenta"
              Img={directivoPhoto1}
              link="/academicos/23?id=23"
            />
            <DirectiveCard
              Nombre="JUAN TRAVNIK"
              profesion="Vicepresidente"
              Img={directivoPhoto2}
              link="/academicos/32?id=32"
            />
            <DirectiveCard
              Nombre="SERGIO BAUR"
              profesion="Secretario"
              Img={directivoPhoto3}
              link="/academicos/3?id=3"
            />
            <DirectiveCard
              Nombre="MARTA PENHOS"
              profesion="Prosecretaria"
              Img={directivoPhoto4}
              link="/academicos/26?id=26"
            />
            <DirectiveCard
              Nombre="JULIO M. VIERA"
              profesion="Tesorero"
              Img={directivoPhoto5}
              link="/academicos/33?id=33"
            />
            <DirectiveCard
              Nombre="GRACIA CUTULI"
              profesion="Protesorera"
              Img={directivoPhoto6}
              link="/academicos/13?id=13"
            />
          </div>
        </Container>
      </div>

      <div className="d-none d-md-block">
        <Container className="CuerpoAcademicoConteiner py-5">
          <Row className="justify-content-center justify-content-lg-between">
            <Col className="LinkAcademicCointeiner">
              <h2
                className="title-article text-center mb-0"
                id="CuerpoAcademico"
              >
                CUERPO ACADÉMICO
              </h2>
              <Link
                to="/academicos"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                <Button variant="dark" className="botonAcademicos" size="lg">
                  Académicos
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>

      <Row className="staffContainerTotal justify-content-center justify-content-lg-between">
        <Col className="StaffConteiner" id="staff">
          <h5 className="preTituloStaff">STAFF PERMANENTE DE LA ANBA</h5>
          <h2 className="title-article text-center mb-0">Staff</h2>
          <div className="py-3 text-center">
            <IconDivisor opacity={0.3} width={100} />
          </div>
          <p className="mb-0 mb-md-5 descr-article text-center">
            La Academia organiza periódicamente concursos, exposiciones y
            conciertos a su vez, concede diversas becas de estudio y de
            perfeccionamiento.
          </p>
        </Col>

        <div className="StaffCardConteiner">
          <StaffCard
            Nombre="ANDRÉS J. BONELLI"
            sector="Secretario General"
            mail="secretario@anba.org.ar"
            Img={staffPhoto1}
          />
          <StaffCard
            Nombre="MARÍA CAROLINA PIANELLI"
            sector="Secretaria Ejecutiva"
            mail="secretaria@anba.org.ar"
            Img={staffPhoto2}
          />
          <StaffCard
            Nombre="MARIANA A. CASTAGNINO"
            sector="Secretaria del Fondo Patrimonial y Acción Cultural"
            mail="accioncultural@anba.org.ar"
            Img={staffPhoto3}
          />
          <StaffCard
            Nombre="GREGORIO M. SÁENZ"
            sector="Difusión y Publicaciones"
            mail="publicaciones@anba.org.ar"
            Img={staffPhoto4}
          />
          <StaffCard
            Nombre="EMILCE CHABBERT"
            sector="Biblioteca y Fondo Patrimonial"
            mail="archivoybiblioteca@anba.org.ar"
            Img={staffPhoto5}
          />
          <StaffCard
            Nombre="MARIANO SCHENONE"
            sector="Asistente de Secretaría General"
            mail="contaduria@anba.org.ar"
            Img={staffPhoto6}
          />
          <StaffCard
            Nombre="GUILLERMO W. TORRES"
            sector="Encargado Servicios Generales"
            mail="info@anba.org.ar"
            Img={staffPhoto7}
          />
          <StaffCard
            Nombre="FLORISA RAMÍREZ CABRAL"
            sector="Asistente de Servicios Generales"
            mail=""
            Img={staffPhoto8}
          />
        </div>
      </Row>
      <Col className="StaffConteiner">
        <h5 className="preTituloStaff">Dónde estamos</h5>
        <h2 className="title-article text-center mb-0">Contáctenos</h2>
        <div className="py-3 text-center">
          <IconDivisor opacity={0.3} width={100} />
        </div>

        <div className="ContactenosIconsContainers">
          <div className="ContactenosIconContainer">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="IconContacto" />
            <p className="ContactoIconText">Sánchez de Bustamante 2663,</p>
            <p className="ContactoIconText">2° Piso (C1425DVA)</p>
            <p className="ContactoIconText">CABA Argentina</p>
            <p class="mb-0 text-black ContactoIconText">Atención al público:</p>
            <p class="mb-0 text-black ContactoIconText">
              Martes, miércoles y jueves de 13 a 18 hs.
            </p>
          </div>

          <div className="ContactenosIconContainer">
            <FontAwesomeIcon icon={faPhone} className="IconContacto" />
            <p className="ContactoIconText">+(54) 9 11 4802-2469</p>
            <p className="ContactoIconText">+(54) 9 11 4802-3490</p>
          </div>

          <div className="ContactenosIconContainer">
            <FontAwesomeIcon icon={faEnvelope} className="IconContacto" />
            <p className="ContactoIconText">info@anba.org.ar</p>
          </div>
        </div>
        <Row className="MapaConteiner">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13139.396778614975!2d-58.411514784153454!3d-34.58268220493539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5626d25ada9%3A0x352c5c2f9b9db352!2sAcademia%20Nacional%20de%20Bellas%20Artes!5e0!3m2!1ses-419!2sar!4v1701274006502!5m2!1ses-419!2sar"
            height="450"
            style={{
              border: 0,
              width: "100%",
              paddingLeft: "10%",
              paddingRight: "10%",
            }}
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Row>
      </Col>
    </section>
  );
}
