import React from "react";
import "./styles.css";
import { Card } from "react-bootstrap";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bg from "./../../assets/img/Bg.png";
import { Link } from "react-router-dom";

export default function DirectiveCard({
  Nombre = "Directivo",
  profesion = "profesión",
  Img = bg,
  link = "#",
  blancoYnegro = false,
}) {
  return (
    <Card className="DirectiveCard">
      <Link
        to={link}
        className="DirectiveLink"
        onClick={() => {
          window.scroll(0, 0);
        }}
      >
        <img
          className="DirectiveCardImg"
          alt="Sample"
          src={Img}
          style={{ filter: `${blancoYnegro && "grayscale(100%)"}` }}
        />
      </Link>
      <div className="DirectiveTextConteiner">
        <h2 className="DirectiveCardTitle" id="nombre">
          {Nombre}
        </h2>

        <span className="DirectiveCardJob">{profesion}</span>
      </div>
    </Card>
  );
}
