export default [
  {
    tipo: "Pintura",
    año: 1993,
    artista: "Carlos Gorriarena",
    título: "Región, 1992",
    descripción: "Acrílico sobre tela. 200 x 200 cm.",
    donadoA: "Museo de Arte Moderno, Buenos Aires",
    image: "1993 Pintura Gorriarena.jpg",
  },
  {
    tipo: "Pintura",
    año: 1994,
    artista: "Adolfo Nigro",
    título: "Ritmos del mar, 1992",
    descripción: "Óleo sobre tela. 200 x 150 cm.",
    donadoA: "Museo Municipal de Bellas Artes “Juan B. Castagnino”, Rosario",
    image: "1994 Pintura Nigro.jpg",
  },
  {
    tipo: "Escultura",
    año: 1995,
    artista: "Pajaro Gómez",
    título: "Ajuste de señales",
    descripción: "Hierro-madera-ensamblado. 140 x 180 x 240 cm.",
    donadoA: "Museo Provincial de Bellas Artes “Emilio E. Caraffa”, Córdoba",
    image: "1995 Escultura Gomez.jpg",
  },
  {
    tipo: "Pintura",
    año: 1996,
    artista: "Kenneth Kemble",
    título: "Radiando mariposas (1995)",
    descripción: "Pintura acrílica sobre tela. 150 x 172 cm.",
    donadoA: "Museo de Arte Moderno, Buenos Aires",
    image: "1996 Pintura Kemble.jpg",
  },
  {
    tipo: "Pintura",
    año: 1997,
    artista: "Juan Mele",
    título: "Obra Nº 524, 1994",
    descripción: "Relieve en madera y pintura acrílica. 150 x 150 x 95 cm.",
    donadoA: "Museo de Arte Moderno, Buenos Aires",
    image: "1997 Pintura Mele.jpeg",
  },
  {
    tipo: "Dibujo",
    año: 1997,
    artista: "Jorge Tapia",
    título: "Enigma de las esferas",
    descripción: "Grafito H.B. 48 x 33 cm.",
    donadoA:
      "Museo de Dibujo y Grabado “Guaman Poma”, Concepción del Uruguay, Entre Ríos",
    image: "1997 Dibujo TAPIA.jpg",
  },
  {
    tipo: "Escultura",
    año: 1998,
    artista: "Alberto Baston Díaz",
    título: "Serie de la Rivera, 1997",
    descripción: "Hierro patinado. 200 x 150 x 160 cm.",
    donadoA:
      "Museo de Arte Contemporáneo Latinoamericano, La Plata, Buenos Aires",
    image: "1998 Escultura Baston.jpg",
  },
  {
    tipo: "Grabado",
    año: 1998,
    artista: "Beatriz Moreiro",
    título: "Arquetipos, 1998",
    descripción:
      "Aguafuerte-aguatinta (papel hecho a mano). 85 x 120 x 100 cm.",
    image: "1998 Grabado Moreiro.jpg",
  },
  {
    tipo: "Pintura",
    año: 1999,
    artista: "Artemio Alisio",
    título: "El mago II/Popol Vuh",
    descripción: "Acrílico sobre tela. 150 x 150 cm.",
    donadoA: "Museo Municipal de Bellas Artes de Tandil (MUMBAT), Buenos Aires",
    image: "1999 Pintura Alisio.jpeg",
  },
  {
    tipo: "Dibujo",
    año: 1999,
    artista: "Carlos Carmona",
    título: "Caminantes",
    descripción: "Tinta. 82 x 102 cm.",
    donadoA: "Museo Provincial “Dr. Pedro Martínez”, Entre Ríos",
    image: "1999 Dibujo Carmona.jpg",
  },
  {
    tipo: "Grabado",
    año: 2000,
    artista: "Mele Bruniard",
    título: "Damero para un bestiario",
    descripción: "Xilografía 80 x 80 cm.",
    donadoA: "Museo Provincial de Bellas Artes “René Brusau”, Resistencia",
    image: "2000 Grabado Bruniard.jpg",
  },
  {
    tipo: "Pintura",
    año: 2000,
    artista: "Blas Castagna",
    título: "Eolia II",
    descripción: "Técnica mixta. 157 x 157 cm.",
    donadoA:
      "Museo Provincial de Bellas Artes “Timoteo E. Navarro”, San Miguel de Tucumán",
    image: "2000 Pintura Blas Castagna.jpg",
  },
  {
    tipo: "Escultura",
    año: 2001,
    artista: "Ferruccio Polacco",
    título: "Forma expandiéndose",
    descripción: "Acero. 93 x 100 x 50 cm.",
    donadoA:
      "Museo Provincial de Bellas Artes “Rosa Galisteo de Rodríguez”, Santa Fe",
    image: "2001 Escultura Polacco.jpg",
  },
  {
    tipo: "Dibujo",
    año: 2001,
    artista: "Marcelo Mayorga",
    título: "Dibujo III",
    descripción: "Dibujo lápiz-carbón. 70 x 100 cm.",
    donadoA: "Museo Provincial de Bellas Artes, Salta",
    image: "2001 Dibujo Mayorga.jpg",
  },
  {
    tipo: "Grabado",
    año: 2002,
    artista: "Estela Zariquiegui",
    título: "Entre el cielo y el infierno-Cielo",
    descripción: "Técnica mixta. 120 x 87 cm.",
  },
  {
    tipo: "Pintura",
    año: 2002,
    artista: "Juan Lecuona",
    título: "Jontxo",
    descripción: "Técnica mixta sobre tela. 200 x 170 cm.",
    donadoA:
      "Museo Municipal de Bellas Artes “Urbano Poggi”, Rafaela, Santa Fe",
    image: "2002 Pintura Lecuona.jpg",
  },
  {
    tipo: "Dibujo",
    año: 2003,
    artista: "Roberto Elia",
    título: "Sobre las arenas II",
    descripción: "Medios mixtos sobre papel. 100 x 120 cm.",
    donadoA: "Museo Municipal de Bellas Artes “Juan B. Castagnino”, Rosario",
  },
  {
    tipo: "Pintura",
    año: 2003,
    artista: "Luis Alberto Wells",
    título: "Las próximas horas",
    descripción: "Acrílico sobre tela. 150 x 200 cm.",
    donadoA: "Museo Provincial de Bellas Artes, La Plata, Buenos Aires",
  },
  {
    tipo: "Escultura",
    año: 2004,
    artista: "Rodolfo Nardi",
    título: "Seducción",
    descripción: "Madera policromada. 63 x 30 x 220 cm.",
    donadoA: "Museo Nacional de Bellas Artes, CABA",
    image: "2004 Escultura 2004.jpg",
  },
  {
    tipo: "Grabado",
    año: 2004,
    artista: "Fabián Velasco",
    título: "La vida común I",
    descripción: "Xilografía. 92 x 62 cm.",
    donadoA: "Museo de Bellas Artes “René Brusau”, Resistencia, Chaco",
  },
  {
    tipo: "Dibujo",
    año: 2005,
    artista: "Catalina Chervin",
    título: "Serie sobre el Apocalipsis I",
    descripción: "Tinta-lápiz-carbón. 120 x 40 cm.",
    donadoA: "Museo Provincial de Bellas Artes “Franklin Rawson”, San Juan",
    image: "2005 Dibujo Chervin.jpg",
  },
  {
    tipo: "Pintura",
    año: 2005,
    artista: "Juan José Cambre",
    título: "Azul-verde (Venecia)",
    descripción: "Acrílico sobre tela. 215 x 225 cm.",
    donadoA: "Museo Provincial de Bellas Artes Franklin Rawson, San Juan",
  },
  {
    tipo: "Grabado",
    año: 2006,
    artista: "Olga Autunno",
    título: "Serie Columnas de la Memoria III",
    descripción: "Litografía. 132 x 94 cm.",
    donadoA: "Museo de Arte Contemporáneo Latinoamericano, La Plata",
    image: "2006 Grabado Autunno.jpg",
  },
  {
    tipo: "Pintura",
    año: 2006,
    artista: "Rogelio Polesello",
    título: "Landing",
    descripción: "Acrílico sobre tela. 200 x 200 cm.",
    donadoA: "Museo de Arte Latinoamericano de Buenos Aires, CABA",
    image: "2006 Pintura Polesello.jpg",
  },
  {
    tipo: "Dibujo",
    año: 2007,
    artista: "Jorge Meijide",
    título: "Sr. Linterna",
    descripción: "Tinta sobre papel. 70 x 100 cm.",
    donadoA: "Museo de Arte Contemporáneo, Salta",
    image: "2007 Dibujo Meijide.jpg",
  },
  {
    tipo: "Escultura",
    año: 2007,
    artista: "Raúl Fernández Olivi",
    título: "Dos orillas",
    descripción: "Acero soldado y pintado. 130 x 100 x 98 cm.",
    donadoA: "Ñande MAC, Corrientes",
    image: "2007 Escultura Fernandez Olivi.jpg",
  },
  {
    tipo: "Grabado",
    año: 2008,
    artista: "Rodolfo Agüero",
    título: "Acto III de una ópera inanimada",
    descripción: "Técnica digital. 80 x 80 cm.",
    donadoA: "Museo de Arte López Claro - Azul, Buenos Aires",
    image: "2008 Grabado Aguero.jpg",
  },
  {
    tipo: "Pintura",
    año: 2008,
    artista: "María Ester Joao",
    título: "Punto y aparte",
    descripción: "Acrílico e hilos sobre tela. 170 x 170 cm.",
    donadoA: "Museo Nacional de Bellas Artes Neuquén",
    image: "2008 Pintura Joao.jpg",
  },
  {
    tipo: "Dibujo",
    año: 2009,
    artista: "Armando Sapia",
    título: "Guido D´Amantea pinta el triángulo de la ilusión y la caída",
    descripción: "Tinta china sobre papel Schoeller. 50 x 70 cm.",
    donadoA: "Museo de Arte Contemporáneo, Salta",
    image: "2009 Dibujo Sapia.jpg",
  },
  {
    tipo: "Pintura",
    año: 2009,
    artista: "Enrique Salvatierra",
    título: "Shinkal",
    descripción: "Técnica mixta. 190 x 182 cm.",
    donadoA: "Museo Municipal de Bellas Artes de Tandil (MUMBAT), Buenos Aires",
    image: "2009 Pintura Salvatierra.jpg",
  },
  {
    tipo: "Escultura",
    año: 2010,
    artista: "Lydia Galego",
    título: "El Rollo",
    descripción: "Telas enduidas sobre base rígidas. 180 x 60 x 60 cm.",
    donadoA: "Museo Provincial de Bellas Artes Franklin Rawson, San Juan",
    image: "2010 Escultura Galego.jpg",
  },
  {
    tipo: "Grabado",
    año: 2010,
    artista: "Alicia Diaz Rinaldi",
    título: "En equilibrio",
    descripción: "Punta seca stencil. 110 x 74 cm.",
    image: "2010 Grabado Diaz Rinaldi.jpg",
  },
  {
    tipo: "Dibujo",
    año: 2011,
    artista: "Mónica Millán",
    título: "El río bord(e)ado",
    descripción: "Lápiz sobre papel. 130 x 100 cm.",
    donadoA: "Museo de Arte Contemporáneo, Salta",
    image: "2011 Dibujo Millan.jpg",
  },
  {
    tipo: "Pintura",
    año: 2011,
    artista: "María Silvia Corcuera Terán",
    título: "Mar de llanto 2",
    descripción: "Masilla epoxi y acrílico sobre madera. 110 x 184 cm.",
    donadoA: "Museo de Arte Contemporáneo “Raúl Lozza”, Alberti, Bs. As.",
    image: "2011 Pintura Corcuera Teran.jpg",
  },
  {
    tipo: "Grabado",
    año: 2012,
    artista: "Marta Perez Temperley",
    título: "Peces de Tailandia",
    descripción: "Agua fuerte agua tinta sobre chapa de zinc. 160 x 80 cm.",
    donadoA: "Museo de Bellas Artes “Juan Ramón Vidal”, Corrientes",
    image: "2012 Grabado Perez Temperley.JPG",
  },
  {
    tipo: "Pintura",
    año: 2012,
    artista: "Elsa Soibelman",
    título: "De la serie: “…entonces advertí que ella era yo”",
    descripción: "Óleo y acrílico sobre tela. 180 x 150 cm.",
    donadoA:
      "Museo Municipal de Bellas Artes “Juan B. Castagnino”, Rosario, Santa Fe",
    image: "2012 Pintura Soibelman.JPG",
  },
  {
    tipo: "Dibujo",
    año: 2013,
    artista: "Juan Andrés Videla",
    título: "Mi otro Jardín",
    descripción: "Grafito sobre Placa. 88 x 120 cm.",
    donadoA: "Museo Provincial de Bellas Artes, Salta",
    image: "2013 Dibujo Videla.jpg",
  },
  {
    tipo: "Escultura",
    año: 2013,
    artista: "Marina Papadopoulos",
    título: "Trama VI",
    descripción: "Acero inoxidable doblado y trenzado. 180 x 200 x 200 cm.",
    donadoA: "Museo Nacional de Bellas Artes Neuquén",
    image: "2013 Escultura Papadopoulus.jpg",
  },
  {
    tipo: "Otros Soportes",
    año: 2014,
    artista: "Mariela Yeregui",
    título: "Hay cadáveres",
    descripción: "Instalación lumínica-mecánica. Medidas variables",
    donadoA: "Museo de la Memoria, Rosario, Santa Fe",
    image: "2014 Otros Soportes Yeregui.jpg",
  },
  {
    tipo: "Pintura",
    año: 2015,
    artista: "Carlos Gómez Centurión",
    título: "Finitud",
    descripción: "Técnica mixta sobre Tela. 250 x 150 cm.",
    donadoA: "MUMBAT (Tandil), Bs. As.",
    image: "2015 Pintura Gomez Centurion.jpg",
  },
  {
    tipo: "Grabado",
    año: 2016,
    artista: "Andrea Moccio",
    título: "Reversibles",
    descripción:
      "Instalación (impresión láser y técnica mixta sobre papel de fibras), dimensiones variables.",
    donadoA: "Museo de Arte de Tigre, Buenos Aires",
    image: "2016 Grabado Moccio.jpg",
  },
  {
    tipo: "Escultura",
    año: 2017,
    artista: "Susana Dragotta",
    título: "Pieza de armadura",
    descripción:
      "Textiles vinílicos, broches de presión, cinta mochilera. 160 cm. X 135 cm. X 110 cm.",
    donadoA: "MUBA, Museo de Bellas Artes 'René Brusau', Resistencia, Chaco",
    image: "2017 Escultura Dragotta.jpg",
  },
  {
    tipo: "Dibujo",
    año: 2018,
    artista: "Miriam Peralta",
    título: "Una vuelta por el universo",
    descripción: "Tinta de plata sobre papel. 116 x 116 cm.",
    image: "2018 Dibujo Peralta.jpg",
  },
  {
    tipo: "Otros Soportes",
    año: 2019,
    artista: "Florencia Levy",
    título: "Lugar fósil",
    descripción: "Video instalación.",
    donadoA: "Museo Nacional de Bellas Artes, CABA",
    image: "2019 Otros soportes Levy.jpg",
  },
  {
    tipo: "Pintura",
    año: 2020,
    artista: "Leila Tschopp",
    título: "S/T, Serie el mar de hielo (tríptico)",
    descripción: "Acrílico sobre tela y estructura de hierro - 150 x 630 cm",
    donadoA: "ÑANDE MAC - Corrientes",
  },
  {
    tipo: "Pintura",
    año: 2020,
    artista: "Leila Tschopp",
    título: "S/T, Serie el mar de hielo (tríptico), 2021",
    descripción: "Acrílico sobre tela y estructura de hierro. 150 x 630 cm.",
    donadoA: "ÑANDE MAC - Corrientes",
    image: "2022 Pintura Tschopp.jpg",
  },
  {
    tipo: "Grabado",
    año: 2022,
    artista: "Elisa O’farrell",
    título: "Un desastre manifiesto, 2019",
    descripción: "Aguatinta. Instalación de medidas variables.",
    image: "2022 Grabado Ofarrel.jpg",
  },
  {
    tipo: "Escultura",
    año: 2023,
    artista: "Nadia Guthmann",
    título: "Cuerpos transitorios, 2023",
    descripción: "Hierro desplegado y tela metálica. 200 x 100 x 178 cm.",
    image: "2023 Escultura Guthman.jpg",
  },
];
