import { Link } from "react-router-dom";
import React from "react";

import bg from "./../../assets/img/Bg.png";
import "./style.css";

export default function AcademicLastCard({
  img = bg,
  titulo = "nombre Academico",
  tipo = "tipo academico",
  link = "#",
}) {
  return (
    <>
      <div className="DividerAcademico"></div>

      <div className="Incorporado">
        <img src={img} alt={titulo} className="imgAcademicos" />
        <div className="textConteiner">
          <p className="tituloIncorporado">{titulo}</p>
          <p className="tipoAcademicoIncorporado">{tipo}</p>
          <Link
            to={link}
            onClick={() => {
              window.scroll(0, 0);
            }}
            className="linkIncorporado"
          >
            <span className="verMas">Ver más...</span>
          </Link>
        </div>
      </div>
    </>
  );
}
