function removeAccents(str) {
    return str.replace(/[áéíóúÁÉÍÓÚ]/g, function (match) {
        switch (match) {
            case 'á':
                return 'a';
            case 'é':
                return 'e';
            case 'í':
                return 'i';
            case 'ó':
                return 'o';
            case 'ú':
                return 'u';
            case 'Á':
                return 'A';
            case 'É':
                return 'E';
            case 'Í':
                return 'I';
            case 'Ó':
                return 'O';
            case 'Ú':
                return 'U';
        }
    });
}

export default function OrdenarAcademicos(a, b) {

    let nombre1 = removeAccents(a.props.Nombre);
    let nombre2 = removeAccents(b.props.Nombre);

    if (nombre1 < nombre2) {
        return -1;
    }
    if (nombre1 > nombre2) {
        return 1;
    }
    return 0;
}