import React from "react";

import "./style.css";
import IconDivisor from "../../components/IconDivisor";
import DirectiveCard from "../../components/DirectiveCard";
import directivoPhoto1 from "./../../assets/img/DirectivosFundacion/1.jpg";
import directivoPhoto2 from "./../../assets/img/DirectivosFundacion/2.jpg";
import directivoPhoto3 from "./../../assets/img/DirectivosFundacion/3.jpg";
import directivoPhoto4 from "./../../assets/img/DirectivosFundacion/4.jpg";
import directivoPhoto5 from "./../../assets/img/DirectivosFundacion/5.jpg";
import { Row, Container, Col, Spinner } from "react-bootstrap";
import PremiosCard from "../../components/PremiosCard/PremiosCard";
import premios from "./../../utilities/Premios";

const FundacionTrabucco = () => {
  const [loading, setloading] = React.useState(false);
  return (
    <section id="fundacion-trabucco">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/Bg_hitos.png")}
          alt="Sala de plenario"
        />
        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            FUNDACIÓN TRABUCCO
          </h3>
          <p className="subTituloPortada">
            HOME /{" "}
            <span className="subTituloPortadaSpan">FUNDACIÓN TRABUCCO</span>
          </p>
        </div>
      </article>

      <div className="d-md-block">
        <Container className="py-3">
          <Row className=" mb-4 justify-content-center justify-content-lg-between pt-5">
            <Col>
              <div className="text-center">
                <img
                  className=""
                  src={require("../../assets/img/LogoTrabucco.jpg")}
                  alt="Logo Trabucco"
                  width={400}
                />
              </div>
            </Col>
          </Row>

          <Row className="align-items-start">
            <Col md={4} className="mb-4">
              <img
                className=""
                src={require("../../assets/img/Trabucco1.jpg")}
                alt="Alberto Trabucco"
                style={{ width: "100%" }}
              />
            </Col>
            <Col md={8}>
              <p className="mb-0 mb-md-5 descr-article text-left">
                La Fundación Alberto J. Trabucco fue creada por la Academia
                Nacional de Bellas Artes en 1991 con fondos provenientes del
                legado que el distinguido artista, Alberto Trabucco, donó a
                nuestra institución.
                <br />
                <br />
                Alberto J. Trabucco nace en Buenos Aires en 1899. Su madre lo
                introduce desde temprana edad en el mundo de la música y la
                literatura. Artista autodidacta, nunca accedió a realizar
                exhibiciones de sus obras, pero participa regularmente de
                Salones y Premios, obteniendo numerosas distinciones, como el
                Gran Premio de Honor del Salón Nacional en 1965. Falleció en
                1990, a los 91 años. En 2005 la ANBA realizó una muestra en su
                homenaje en el Centro Cultural Borges.
                <br />
                <br />
                La Academia Nacional de Bellas Artes en su Fundación rinde un
                cálido homenaje a la memoria de este hombre modesto y sencillo,
                que fue no sólo uno de los grandes artistas de su generación
                sino que dispuso con generosidad poco habitual la creación de la
                Fundación para estimular y continuar su acción en beneficio de
                la cultura, especialmente de las artes visuales.
                <br />
                <br />
                La Fundación otorga anualmente la Beca Nacional de proyectos de
                investigación en el campo de las artes visuales y el Premio
                Adquisición Alberto J. Trabucco.
              </p>
            </Col>
          </Row>

          <Row>
            <p className="mb-md-5 descr-article text-left">
              Este Premio, de carácter consagratorio, implementado en 1993,
              sucede al Premio Palanza otorgado por la ANBA desde 1946 y convoca
              desde sus inicios las disciplinas Pintura, Grabado, Escultura y
              Dibujo. En 2014 se incorporó Otros soportes a las ya mencionadas,
              actualizando la convocatoria a artistas que no se encuadran dentro
              de las clásicas categorías que hasta ese momento proponía el
              Premio. La obra adquirida por la Academia  es donada a un Museo
              Nacional, Provincial o Municipal de nuestro país.
              <br />
              <br />
              Las disciplinas se alternan año a año y los 10 artistas invitados
              a participar al Premio son seleccionados por la Comisión de Artes
              Visuales de la ANBA.
              <br />
              <br />
              Los Premios Trabucco han sido expuestos en Museos e Instituciones
              como el Museo Nacional de Bellas Artes, el Centro Cultural Borges,
              el Centro Cultural Recoleta y la Fundación Klemm.
            </p>
          </Row>

          <Row className="justify-content-center justify-content-lg-between pt-5">
            <Col>
              <h3 className="preTitulo article-subtitle mb-1">
                ACADEMIA NACIONAL DE BELLAS ARTES
              </h3>
              <h2 className="title-article text-center mb-0">
                Consejo de Administración Fundación Alberto J. Trabucco
              </h2>
              <div className="py-3 text-center">
                <IconDivisor opacity={0.3} width={100} />
              </div>
            </Col>
          </Row>

          <div
            className="DirectiveFundacionCardConteiner"
            style={{ marginBottom: "-10%" }}
          >
            <DirectiveCard
              Nombre="JOSÉ MARCHI"
              profesion="Presidente"
              Img={directivoPhoto1}
              link="/academicos/22?id=22"
            />
            <DirectiveCard
              Nombre="GRACIA CUTULI"
              profesion="Secretaria"
              Img={directivoPhoto2}
              link="/academicos/13?id=13"
              blancoYnegro={true}
            />
            <DirectiveCard
              Nombre="CAROLA ZECH"
              profesion="Prosecretaria"
              Img={directivoPhoto3}
              link="/academicos/34?id=34"
              blancoYnegro={true}
            />
            <DirectiveCard
              Nombre="LAURA MALOSETTI COSTA"
              profesion="Tesorera"
              Img={directivoPhoto4}
              link="/academicos/21?id=21"
              blancoYnegro={true}
            />
            <DirectiveCard
              Nombre="GRACIELA TAQUINI"
              profesion="protesorera"
              Img={directivoPhoto5}
              link="/academicos/31?id=31"
              blancoYnegro={true}
            />
          </div>

          <Row className="justify-content-center justify-content-lg-between pt-5">
            <Col>
              <h2 className="title-article text-center mb-0">
                Premios Trabucco por año, obra y lugar de donación
              </h2>
              <div className="py-3 text-center">
                <IconDivisor opacity={0.3} width={100} />
              </div>
            </Col>
          </Row>

          <Row
            className="justify-content-center justify-content-lg-between"
            style={{ marginTop: 30 }}
          >
            {loading ? (
              <>
                <Row className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Row>
              </>
            ) : (
              <div className="premiosConatainer">
                {premios
                  .sort((a, b) => (a.año > b.año ? 1 : -1))
                  .map((premio, index) => (
                    <>
                      {premio.image && (
                        <PremiosCard
                          id={index}
                          anio={premio.año}
                          imagen={premio.image}
                          artista={premio.artista}
                          tipo={premio.tipo}
                          donadoA={premio.donadoA}
                          titulo={premio.título}
                        />
                      )}
                    </>
                  ))}
              </div>
            )}
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default FundacionTrabucco;
