import React, { useEffect, useState } from "react";
import "./styles.css";
import bg from "./../../assets/img/Bg.png";
import "./../../assets/css/ckeditor.css";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import IconDivisor from "../../components/IconDivisor";
import { REST_URL, REST_URL_IMAGES } from "./../../config/env";
import AcademicRelacionadoCard from "../../components/AcademicRelacionadoCard";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useParams } from "react-router-dom";

export default function AcademicosSingle({ Img = bg }) {
  const [academico, setacademico] = useState();
  const [academicos, setacademicos] = useState();
  const [loading, setloading] = useState(false);
  const [car1, setcar1] = useState(false);
  const [car2, setcar2] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    buscarDatosAcademico();
    buscarAcademicosRelacionados();

    if (car1 === true && car2 === true) {
      setloading(true);
      localStorage.setItem("ultimoAcademico", academico.tipo);
    }
  }, [car1, car2]);

  async function buscarDatosAcademico() {
    await fetch(REST_URL + "/academicos/" + id)
      .then((response) => response.json())
      .then((rawJson) => {
        setacademico(rawJson[0]);
        setcar1(true);
      });
  }

  async function buscarAcademicosRelacionados() {
    fetch(REST_URL + "/academicos", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((rawJson) => {
        let academicosArrayEtiquetas = [];
        let j = 0;

        //Recorre todos los academicos.
        rawJson.map((elemento) => {
          //guarda en el array todos los academicos.
          if (
            elemento.tipo === academico.tipo &&
            academico.disciplina === elemento.disciplina
          ) {
            let etiqueta = (academicosArrayEtiquetas[j] = (
              <AcademicRelacionadoCard
                key={j}
                nombre={elemento.nombre}
                tipo={elemento.tipo}
                disciplina={elemento.disciplina}
                img={
                  REST_URL_IMAGES + "/academicos_imagenes/" + elemento.imagen
                }
                id={elemento.id}
              />
            ));
            j++;

            return etiqueta;
          }
          return <></>;
        });
        setacademicos(academicosArrayEtiquetas);
        setcar2(true);
      });
  }

  const responsive = {
    0: {
      items: 2,
      itemsFit: "center",
    },
    1024: {
      items: 5,
      itemsFit: "contain",
    },
  };

  return (
    <div id="AcademicSingle">
      <div className="AcademicCover">
        <Container className="py-3">
          {loading ? (
            <>
              <div className="AcademicCoverInfoContainer">
                <img
                  className="AcademicCoverImage"
                  src={
                    REST_URL_IMAGES + "/academicos_imagenes/" + academico.imagen
                  }
                  alt={academico.nombre}
                />

                <div className="InfoAcademicConteiner">
                  <div className="AcademicCoverTitleContainer">
                    <h1 className="AcademicCoverTitle">{academico.nombre}</h1>
                    <div>
                      <IconDivisor />
                    </div>
                  </div>
                  <p className="AcademicCoverTipe">{academico.tipo == 'Académicos delegados honorios' ? 'Académicos delegados honorarios' : academico.tipo}</p>
                  <p className="AcademicCoverText">{academico.sitial}</p>

                  {academico.fechaNacimiento !== "01/01/0000" ? (
                    <>
                      {console.log(academico)}
                      <p className="AcademicCoverText">
                        Nacimiento: {academico.fechaNacimiento}
                      </p>
                    </>
                  ) : (
                    <>
                      {academico.anio != null ? (
                        <>
                          <p className="AcademicCoverText">
                            Nacimiento: {academico.anio}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {academico.fechaNombramiento !== "01/01/0000" ? (
                    <>
                      <p className="AcademicCoverText">
                        Nombramiento: {academico.fechaNombramiento}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                  {academico.pais && (academico.tipo == 'Académicos correspondientes' || academico.tipo == 'Académicos fallecidos') ? (
                    <>
                      <p className="AcademicCoverText">
                        País: {academico.pais}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                  {academico.provincia && (academico.tipo == 'Académicos delegados' || academico.tipo == 'Académicos delegados honorios' || academico.tipo == 'Académicos fallecidos') ? (
                    <>
                      <p className="AcademicCoverText">
                        Provincia: {academico.provincia}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="AcademicCoverInfoContainer">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: 400, width: 400 }}
                >
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ color: "white" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>

                <div className="InfoAcademicConteiner">
                  <div className="AcademicCoverTitleContainer">
                    <h1 className="AcademicCoverTitle">Cargando...</h1>
                    <div>
                      <IconDivisor />
                    </div>
                  </div>
                  <p className="AcademicCoverTipe">Cargando...</p>
                </div>
              </div>
            </>
          )}

          {loading ? (
            <>
              <div className={`${academico.tipo !== "Académicos fallecidos" ? 'contenedorTipoDisciplina' : 'contenedorTipoDisciplinaSecundaria'}`}>
                {academico.tipo !== "Académicos fallecidos" && <div className="ContenedorDatosAzul">
                  <h5>{academico.tipo == 'Académicos de número' ? 'CATEGORÍA' : 'TIPO'}</h5>
                  <p>{academico.tipo == 'Académicos delegados honorios' ? 'Académicos delegados honorarios' : academico.tipo}</p>
                </div>}

                <div className="ContenedorDatosAzul">
                  <h5>DISCIPLINA</h5>
                  <p>{academico.disciplina}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="contenedorTipoDisciplina">
                <div className="ContenedorDatosAzul">
                  <h5>TIPO</h5>
                  <p>Cargando...</p>
                </div>

                <div className="ContenedorDatosAzul">
                  <h5>DISCIPLINA</h5>
                  <p>Cargando...</p>
                </div>
              </div>
            </>
          )}
        </Container>
      </div>

      <div className="d-md-block">
        <Container className="py-3">
          <div className="AcademicoDescripcionTotalContainer">
            <Row className="justify-content-center justify-content-lg-between">
              <Col>
                {loading ? (
                  <>
                    <h2 className="title-article text-center mb-0">
                      {academico.nombre}
                    </h2>
                    <div className="py-3 text-center">
                      <IconDivisor opacity={0.3} width={100} />
                      <p
                        id="descripcion"
                        className="mb-0 mb-md-5 descr-article text-center ck-content"
                        dangerouslySetInnerHTML={{
                          __html: academico.descripcion,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <h2 className="title-article text-center mb-0">
                      Cargando...
                    </h2>
                    <div className="py-3 text-center">
                      <IconDivisor opacity={0.3} width={100} />
                      <p className="mb-0 mb-md-5 descr-article text-center">
                        Cargando...
                      </p>
                    </div>
                  </>
                )}

                <h5 className="RelacionadosTitle">
                  ACADÉMICOS RELACIONADOS POR CATEGORIA
                </h5>

                {loading ? (
                  <>
                    <div className="containerCardRelacionado">
                      <AliceCarousel
                        disableSlideInfo="false"
                        mouseTracking
                        items={academicos}
                        responsive={responsive}
                        infinite
                        autoPlay
                        activeIndex
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="containerCardRelacionado">
                      <h5>Cargando...</h5>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}
