import React, { useEffect, useState } from "react";
import "./styles.css";
import { Container, Col, Spinner } from "react-bootstrap";
import bg from "./../../assets/img/Bg.png";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { REST_URL } from "../../config/env";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import "./../../assets/css/ckeditor.css";

export default function NoticiasSinlge() {
  const [noticia, setnoticia] = useState();
  const [loading, setloading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    buscarDatosNoticia();
  }, []);

  async function buscarDatosNoticia() {
    await fetch(REST_URL + "/agenda/" + id)
      .then((Response) => Response.json())
      .then((rawJson) => {
        setnoticia(rawJson[0]);
        setloading(true);
      });
  }

  return (
    <section id="NoticasSinlge">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/Bg_Agenda.png")}
          alt="Portada Agenda"
        />
        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            AGENDA
          </h3>
          <p className="subTituloPortada">
            INICIO / AGENDA
            <span className="subTituloPortadaSpan">
              {loading ? (
                <>{" / " + noticia?.titulo}</>
              ) : (
                <>{" / Cargando..."}</>
              )}
            </span>
          </p>
        </div>
      </article>

      <div className="d-md-block">
        <Container className="ContainerFondoDocumentalSingle py-3">
          {loading ? (
            <>
              <div className="BodyFondoDocumental">
                <img
                  id="imagenNoticia"
                  src={
                    "https://anba-admin.depcsuite.com/agenda_imagenes/" +
                    noticia?.imagen
                  }
                  alt={noticia?.titulo}
                  className="fondoDocumentalImg"
                />
                <div id="noticia">
                  <div className="PublicacionSingleTitulosContainer">
                    <h5 className="FondoDocumentalSubTitulo">
                      {noticia?.categoria}
                    </h5>
                    <h5 className="FondoDocumentalSubTitulo">
                      {noticia?.fechaPublicacion}
                    </h5>
                  </div>
                  <div className="FondoDocumentalDivider"></div>
                  <h3 className="FondoDocumentalTitulo">{noticia?.titulo}</h3>
                  <h3 className="PublicacionSingleSubTitulo">
                    {noticia?.subtitulo}
                  </h3>
                  <p
                    className="FondoDocumentalDescripcion ck-content"
                    dangerouslySetInnerHTML={{ __html: noticia?.descripcion }}
                  />
                  <div className="containerLinkspdf">
                    {noticia?.pdf1 != null ? (
                      <>
                        <a
                          className="PublicacionSingleLinkPdf"
                          target="_black"
                          href={
                            "https://anba-admin.depcsuite.com/agenda_pdf/" +
                            noticia?.pdf1
                          }
                        >
                          {noticia?.pdf1}
                        </a>
                      </>
                    ) : (
                      <></>
                    )}
                    {noticia?.pdf2 != null ? (
                      <>
                        <a
                          className="PublicacionSingleLinkPdf"
                          target="_black"
                          href={
                            "https://anba-admin.depcsuite.com/agenda_pdf/" +
                            noticia?.pdf2
                          }
                        >
                          {noticia?.pdf2}
                        </a>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="PublicacionSingleCompartir">
                  <Link
                    className="PublicacionSingleLink"
                    to={"/noticias"}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Volver a agenda
                  </Link>
                  <div className="PublicacionSingleCompatirContainer">
                    <p className="Compartir">COMPARTIR : </p>
                    <FacebookShareButton url={window.location.href}>
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        id="FondoDocumentalIconFacebook"
                      />
                    </FacebookShareButton>
                    <TwitterShareButton url={window.location.href}>
                      <FontAwesomeIcon
                        icon={faTwitter}
                        className="FondoDocumentalIcon"
                      />
                    </TwitterShareButton>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="BodyFondoDocumental">
                <Col className="d-flex justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
                <div className="PublicacionSingleTitulosContainer">
                  <h5 className="FondoDocumentalSubTitulo">CATEGORIA</h5>
                  <h5 className="FondoDocumentalSubTitulo">fecha</h5>
                </div>
                <div className="FondoDocumentalDivider"></div>
                <h3 className="FondoDocumentalTitulo">CARGANDO...</h3>
                <h3 className="PublicacionSingleSubTitulo">CARGANDO...</h3>
                <p className="FondoDocumentalDescripcion">
                  Cargando descripcion...
                </p>
                <div className="PublicacionSingleCompartir">
                  <Link
                    className="PublicacionSingleLink"
                    to={"/noticias"}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Volver a agenda
                  </Link>
                  <div className="PublicacionSingleCompatirContainer">
                    <p className="Compartir">COMPARTIR : </p>
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      id="FondoDocumentalIconFacebook"
                    />
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="FondoDocumentalIcon"
                    />
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="FondoDocumentalIcon"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Container>
      </div>
    </section>
  );
}
