import React, { useEffect, useState } from "react";
import "./styles.css";
import { Row, Container, Col, Spinner } from "react-bootstrap";
import IconDivisor from "../../components/IconDivisor";
import VerticalLine from "../../components/VerticalLine";
import ObrasCard from "../../components/ObrasCard";
import { REST_URL } from "./../../config/env";

export default function FondoDocumental() {
  const [fondoDocumental, setfondoDocumental] = useState();
  const [loading, setloading] = useState(true);
  const [fondoDocumentalCopy, setfondoDocumentalCopy] = useState();
  const [textI, settextI] = useState("");

  useEffect(() => {
    createFondoDocumental();
  }, []);

  useEffect(() => {
    textIntroductorio();
  }, []);

  async function textIntroductorio() {
    await fetch(REST_URL + "/datosControlerOBDA", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((textoI) => {
        settextI(textoI[3].texto_introductorio);
      });
  }

  async function createFondoDocumental() {
    await fetch(REST_URL + "/fondoDocumental", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((rawJson) => {
        let fondoDocumentalAuxArray = [];
        let j = -1;

        rawJson.map((element) => {
          j++;
          return (fondoDocumentalAuxArray[j] = (
            <ObrasCard
              id={element.id}
              key={j}
              Nombre={element.titulo}
              autor={element.autor}
              type={element.categoria}
              Img={
                "https://anba-admin.depcsuite.com/fondo_documental_imagenes/" +
                element.imagen
              }
            />
          ));
        });

        setfondoDocumental(fondoDocumentalAuxArray);
        setfondoDocumentalCopy(fondoDocumentalAuxArray);
        setloading(false);
      });
  }

  function handleClickFondo() {
    let totalDocumental = fondoDocumentalCopy;

    let arrayAux = [];

    for (let i = 0; i < totalDocumental.length; i++) {
      if (totalDocumental[i].props.type === "Fondo Documental") {
        arrayAux.push(totalDocumental[i]);
      }
    }
    setfondoDocumental(arrayAux);
  }

  function handleClickColeccion() {
    let totalDocumental = fondoDocumentalCopy;

    let arrayAux = [];

    for (let i = 0; i < totalDocumental.length; i++) {
      if (totalDocumental[i].props.type !== "Fondo Documental") {
        arrayAux.push(totalDocumental[i]);
      }
    }
    setfondoDocumental(arrayAux);
  }

  function handleClickTodo() {
    setfondoDocumental(fondoDocumentalCopy);
  }

  return (
    <section id="FondoDocumental">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/Libros.png")}
          alt="Sala de plenario"
        />
        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            Fondo Patrimonial Y COLECCIÓN{" "}
          </h3>
          <p className="subTituloPortada">
            HOME /{" "}
            <span className="subTituloPortadaSpan">
              FONDO PATRIMONIAL Y COLECCIÓN{" "}
            </span>
          </p>
        </div>
      </article>

      <div className="d-md-block">
        <Container className="py-3">
          <Row className="justify-content-center justify-content-lg-between pt-5">
            <Col>
              <h3 className="preTitulo article-subtitle mb-1">
                ACADEMIA NACIONAL DE BELLAS ARTES
              </h3>
              <h2 className="title-article text-center mb-0">
                FONDO PATRIMONIAL Y COLECCIÓN
              </h2>
              <div className="py-3 text-center">
                <IconDivisor opacity={0.3} width={100} />
              </div>
              <p
                className="mb-0 mb-md-5 descr-article text-center"
                dangerouslySetInnerHTML={{
                  __html: textI,
                }}
              />
            </Col>
          </Row>

          <Row className="justify-content-center justify-content-lg-between">
            <div className="SelectCategoryConteiner">
              <p className="selectorCategory" onClick={handleClickColeccion}>
                Colección
              </p>
              <VerticalLine colorLine="#e0e0e0" className="dividerCategory" />
              <p className="selectorCategory" onClick={handleClickFondo}>
                Fondo Patrimonial
              </p>
            </div>
          </Row>

          <Row className="justify-content-center justify-content-lg-between">
            {loading ? (
              <>
                <Row className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Row>
              </>
            ) : (
              <div className="ObrasConteiner">
                <>{fondoDocumental}</>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </section>
  );
}
