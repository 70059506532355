import React from "react";
import './style.css'
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function NotFound() {

    return (
        <>
            <section id="notFound">
                <article className='portada'>
                    <img
                        className='imagenPortada'
                        src={require('../../assets/img/404.png')}
                        alt='frente ANBA'
                    />
                    <div className='position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center'>
                        <h3 className='text-uppercase mb-md-3' id='tituloAcademicos'>404 PÁGINA NO ENCONTRADA</h3>
                        <p className='subTituloPortada'>INICIO / <span className='subTituloPortadaSpan'>404</span></p>
                    </div>
                </article>
                <div className="d-none d-md-block">
                    <Container className="py-5">
                        <span className='notFoundtitulo'>404 página no encontrada</span>
                        <div className="divider404"></div>

                        <Link className="notFoundLink" to="/" onClick={() => { window.scroll(0, 0); }}>volver al inicio</Link>
                    </Container>
                </div>

            </section>
        </>
    )
}