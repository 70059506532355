import React, { useEffect, useState } from "react";
import "./style.css";
import { Container, Col, InputGroup, Form, Spinner } from "react-bootstrap";
import NoticiaCard from "../../components/NoticiaCard";
import ProxEvent from "../../components/ProxEvent";
import HorizontalBlueLine from "../../components/horizontalBlueLine";
import { REST_URL } from "./../../config/env";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numbertotextoMes from "../../utilities/numbertotextMes";

export default function Noticias() {
  const [loading, setloading] = useState(false);
  const [noticias, setNoticias] = useState();
  const [noticiasCopy, setnoticiasCopy] = useState();
  const [event, setevent] = useState();
  const [loadingEvent, setloadingEvent] = useState(false);

  useEffect(() => {
    createNoticias();
    createProxEvent();
  }, []);

  async function createNoticias() {
    await fetch(REST_URL + "/premios", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((rawJson) => {
        const noticiasSorted = rawJson.sort((a, b) => {
          const fechaA = new Date(
            a.fechaPublicacion.split("/").reverse().join("-")
          );
          const fechaB = new Date(
            b.fechaPublicacion.split("/").reverse().join("-")
          );
          return fechaB - fechaA; // Orden ascendente
        });

        let noticiasAuxArray = [];
        let i = -1;

        noticiasSorted.map((element) => {
          i++;
          //separar fecha.
          const fecha = element.fechaPublicacion.split("/");
          return (noticiasAuxArray[i] = (
            <NoticiaCard
              key={i}
              id={element.id}
              titulo={element.titulo}
              tipo={element.categoria}
              texto={element.descripcion}
              Img={
                "https://anba-admin.depcsuite.com/agenda_imagenes/" +
                element.imagen
              }
              dia={fecha[0]}
              mes={numbertotextoMes(fecha[1])}
              anio={fecha[2]}
              fecha={element.fechaPublicacion}
            />
          ));
        });

        setnoticiasCopy(noticiasAuxArray);
        setNoticias(noticiasAuxArray);
        setloading(true);
      });
  }
  //obtener proximo evento.
  async function createProxEvent() {
    await fetch(REST_URL + "/agendaProxEvent", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((rawJson) => {
        if (rawJson[0] !== undefined) {
          setevent(rawJson[0]);
          setloadingEvent(true);
        }
      });
  }

  //buscador

  const handlebuscarNoticias = (event) => {
    let valorBusqueda = event.target.value;
    const regex = new RegExp(valorBusqueda, "i");

    let noticiastotales = noticiasCopy;
    let arrayAux = [];

    noticiastotales.map((noticias) => {
      if (regex.test(noticias.props.titulo)) {
        return arrayAux.push(noticias);
      }
      if (regex.test(noticias.props.descripcion)) {
        return arrayAux.push(noticias);
      }

      if (regex.test(noticias.props.mes)) {
        return arrayAux.push(noticias);
      }

      return 0;
    });

    setNoticias(arrayAux);
  };

  //filtros
  function handleClicktodo() {
    setNoticias(noticiasCopy);
  }

  function handleClickNoticias() {
    let totalNoticias = noticiasCopy;
    let arrayAux = [];

    for (let i = 0; i < totalNoticias.length; i++) {
      if (totalNoticias[i].props.tipo === "Noticias") {
        arrayAux.push(totalNoticias[i]);
      }
    }
    setNoticias(arrayAux);
  }

  function handleClickIncorporaciones() {
    let totalNoticias = noticiasCopy;
    let arrayAux = [];

    for (let i = 0; i < totalNoticias.length; i++) {
      if (totalNoticias[i].props.tipo === "Incorporaciones") {
        arrayAux.push(totalNoticias[i]);
      }
    }
    setNoticias(arrayAux);
  }

  function handleClickPremios() {
    let totalNoticias = noticiasCopy;
    let arrayAux = [];

    for (let i = 0; i < totalNoticias.length; i++) {
      if (totalNoticias[i].props.tipo === "Premios") {
        arrayAux.push(totalNoticias[i]);
      }
    }
    setNoticias(arrayAux);
  }

  function handleClickActividades() {
    let totalNoticias = noticiasCopy;
    let arrayAux = [];

    for (let i = 0; i < totalNoticias.length; i++) {
      if (totalNoticias[i].props.tipo === "Actividades") {
        arrayAux.push(totalNoticias[i]);
      }
    }
    setNoticias(arrayAux);
  }

  function handleClickExhibiciones() {
    let totalNoticias = noticiasCopy;
    let arrayAux = [];

    for (let i = 0; i < totalNoticias.length; i++) {
      if (totalNoticias[i].props.tipo === "Exhibiciones") {
        arrayAux.push(totalNoticias[i]);
      }
    }
    setNoticias(arrayAux);
  }

  function handleClickEventos() {
    let totalNoticias = noticiasCopy;
    let arrayAux = [];

    for (let i = 0; i < totalNoticias.length; i++) {
      if (totalNoticias[i].props.tipo === "Eventos") {
        arrayAux.push(totalNoticias[i]);
      }
    }
    setNoticias(arrayAux);
  }

  function handleClickBecas() {
    let totalNoticias = noticiasCopy;
    let arrayAux = [];

    for (let i = 0; i < totalNoticias.length; i++) {
      if (totalNoticias[i].props.tipo === "Becas") {
        arrayAux.push(totalNoticias[i]);
      }
    }
    setNoticias(arrayAux);
  }
  return (
    <section className="Noticias">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/Bg_Agenda.png")}
          alt="Portada PREMIOS y BECAS"
        />
        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            PREMIOS y BECAS
          </h3>
        </div>
      </article>
      {/* seccion de las cartas */}

      <div className="d-md-block">
        <Container className="py-5">
          <div className="BlogContainer">
            <div className="d-none d-md-block NoticiasCardContainer">
              {loading ? (
                <>{noticias}</>
              ) : (
                <>
                  <Col className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                </>
              )}
            </div>

            {/* Menu lateral */}

            <Col>
              <h5 className="TituloMenuLateral">BUSCAR</h5>
              <HorizontalBlueLine />

              <InputGroup className="searchConteiner mb-3">
                <Form.Control
                  placeholder="Buscar..."
                  aria-describedby="basic-addon2"
                  onChange={handlebuscarNoticias}
                />
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="iconSearch"
                />
              </InputGroup>
              <h5 className="TituloMenuLateral">CATEGORÍAS</h5>
              <HorizontalBlueLine />

              <div className="ListaAcademicos">
    
                <div className="tipoAcademico">
                  <p
                    className="TituloAcademicos"
                    onClick={handleClickPremios}
                  >
                    Premios
                  </p>
                </div>

                <div className="DividerAcademico"></div>

                <div className="tipoAcademico">
                  <p className="TituloAcademicos" onClick={handleClickBecas}>
                    Becas
                  </p>
                </div>
              </div>

              {loadingEvent ? (
                <>
                  <ProxEvent
                    title={event.titulo}
                    Img={
                      "https://anba-admin.depcsuite.com/agenda_imagenes/" +
                      event.imagen
                    }
                    fecha={event.fechaPublicacion}
                    epigrafe={event.descripcion}
                    id={event.id}
                  />
                </>
              ) : (
                <></>
              )}
            </Col>

            <div className="d-block d-md-none NoticiasCardContainer">
              {loading ? (
                <>{noticias}</>
              ) : (
                <>
                  <p>Cargando premios y becas...</p>
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
