import React from "react";
import "./styles.css";
import { Card } from "react-bootstrap";
import bg from "./../../assets/img/Bg.png";
import { Link } from "react-router-dom";

export default function ObrasCard({
  Nombre = "Nombre Obra",
  type = "Coleccion",
  Img = bg,
  id = 0,
  autor = "autor",
}) {
  return (
    <Card className="ObrasCard">
      <Link
        className="ObrasCardLink"
        to={"/fondo-patrimonial/" + id + "?id=" + id}
        onClick={() => {
          window.scroll(0, 0);
        }}
      >
        <img className="ObrasCardImg" alt="Sample" src={Img} />
        <div className="ObrasCardTextConteiner">
          <h2 className="ObrasCardTitulo" id="nombre">
            {autor}
          </h2>
          <h5 className="ObrasCardtype">{Nombre}</h5>
        </div>
      </Link>
    </Card>
  );
}
