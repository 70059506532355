import React from "react";

export default function IconDivisor({ opacity, width }) {
    if (width !== undefined) {
        return (
            <>
                <span className='horizontal-line me-md-4' style={{ opacity: opacity ? opacity : 1, width: width }}></span>
                <img src={require('../../assets/img/icon_agenda.png')} alt='icon_agenda.png' style={{ opacity: opacity ? opacity : 1 }} />
                <span className='horizontal-line ms-md-4' style={{ opacity: opacity ? opacity : 1, width: width }}></span>
            </>
        );
    } else {
        return (
            <>
                <span className='horizontal-line me-md-4' style={{ opacity: opacity ? opacity : 1 }}></span>
                <img src={require('../../assets/img/icon_agenda.png')} alt='icon_agenda.png' style={{ opacity: opacity ? opacity : 1 }} />
                <span className='horizontal-line ms-md-4' style={{ opacity: opacity ? opacity : 1 }}></span>
            </>
        );
    }
}