import React, { useEffect, useState } from "react";
import "./styles.css";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import IconDivisor from "../../components/IconDivisor";
import VerticalLine from "../../components/VerticalLine";
import PublicacionCard from "../../components/PublicacionCard";
import { REST_URL } from "./../../config/env";
import OrdenarPublicaciones from "../../utilities/OrdenarPublicaciones";

export default function Publicaciones() {
  const [publicaciones, setpublicaciones] = useState();
  const [loading, setloading] = useState(true);
  const [publicacionesCopy, setpublicacionesCopy] = useState();
  const [textI, settextI] = useState("");

  useEffect(() => {
    createPublicaciones();
  }, []);

  useEffect(() => {
    textIntroductorio();
  }, []);

  async function textIntroductorio() {
    await fetch(REST_URL + "/datosControlerOBDA", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((textoI) => {
        settextI(textoI[0].texto_introductorio);
      });
  }

  async function createPublicaciones() {
    await fetch(REST_URL + "/Publicacion", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((rawJson) => {
        let PublicacionesAuxArray = [];
        let i = -1;

        rawJson.map((element) => {
          i++;
          console.log(element);
          return (PublicacionesAuxArray[i] = (
            <PublicacionCard
              Titulo={element.titulo}
              SubTitulo={element.subtitulo}
              Categoria={element.categoria}
              texto={element.descripcion}
              Img={
                "https://anba-admin.depcsuite.com/publicaciones_archivos/imagenes/" +
                element.imagen_publicacion
              }
              id={element.id}
              key={i}
              orden={element.orden}
            />
          ));
        });

        PublicacionesAuxArray.sort(OrdenarPublicaciones);
        setpublicaciones(PublicacionesAuxArray);
        setpublicacionesCopy(PublicacionesAuxArray);
        setloading(false);
      });
  }

  function handlClickTotal() {
    setpublicaciones(publicacionesCopy);
  }

  function handleClickIconografia() {
    let totalpublicaciones = publicacionesCopy;

    let arrayAux = [];

    for (let i = 0; i < totalpublicaciones.length; i++) {
      if (totalpublicaciones[i].props.Categoria === "Iconografia del Arte") {
        arrayAux.push(totalpublicaciones[i]);
      }
    }

    arrayAux.sort(OrdenarPublicaciones);
    setpublicaciones(arrayAux);
  }

  function handleClickCatalogos() {
    let totalpublicaciones = publicacionesCopy;

    let arrayAux = [];

    for (let i = 0; i < totalpublicaciones.length; i++) {
      if (totalpublicaciones[i].props.Categoria === "Catálogos") {
        arrayAux.push(totalpublicaciones[i]);
      }
    }
    arrayAux.sort(OrdenarPublicaciones);
    setpublicaciones(arrayAux);
  }

  function handleClickTemas() {
    let totalpublicaciones = publicacionesCopy;

    let arrayAux = [];

    for (let i = 0; i < totalpublicaciones.length; i++) {
      if (totalpublicaciones[i].props.Categoria === "Revista Temas") {
        arrayAux.push(totalpublicaciones[i]);
      }
    }
    arrayAux.sort(OrdenarPublicaciones);
    setpublicaciones(arrayAux);
  }

  function handleClickArgentina() {
    let totalpublicaciones = publicacionesCopy;

    let arrayAux = [];

    for (let i = 0; i < totalpublicaciones.length; i++) {
      if (
        totalpublicaciones[i].props.Categoria === "Historia del arte Argentina"
      ) {
        arrayAux.push(totalpublicaciones[i]);
      }
    }
    arrayAux.sort(OrdenarPublicaciones);
    setpublicaciones(arrayAux);
  }

  function handleClickHistoricas() {
    let totalpublicaciones = publicacionesCopy;

    let arrayAux = [];

    for (let i = 0; i < totalpublicaciones.length; i++) {
      if (totalpublicaciones[i].props.Categoria === "Historicas") {
        arrayAux.push(totalpublicaciones[i]);
      }
    }
    arrayAux.sort(OrdenarPublicaciones);
    setpublicaciones(arrayAux);
  }

  return (
    <section id="Publicaciones">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/TAPApublicaciones.png")}
          alt="Sala de plenario"
        />
        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            Publicaciones
          </h3>
        </div>
      </article>

      <div className="d-md-block">
        <Container className="py-3">
          <Row className="justify-content-center justify-content-lg-between pt-5">
            <Col>
              <h2 className="title-article text-center mb-0">
                PUBLICACIONES DE LA ACADEMIA NACIONAL DE BELLAS ARTES
              </h2>
              <div className="py-3 text-center">
                <IconDivisor opacity={0.3} width={100} />
              </div>
              <p className="mb-0 mb-md-5 descr-article text-center">{textI}</p>
            </Col>
          </Row>

          <div className="DivCategory">
            <div className="SelectCategoryConteiner">
              <p className="selectorCategory" onClick={handlClickTotal}>
                Todo
              </p>
              <VerticalLine colorLine="#e0e0e0" className="dividerCategory" />
              <p className="selectorCategory" onClick={handleClickCatalogos}>
                Catálogos
              </p>
              <VerticalLine colorLine="#e0e0e0" className="dividerCategory" />
              <p className="selectorCategory" onClick={handleClickIconografia}>
                Iconografía del Arte
              </p>
              <VerticalLine colorLine="#e0e0e0" className="dividerCategory" />
              <p className="selectorCategory" onClick={handleClickTemas}>
                Revista TEMAS
              </p>
              <VerticalLine colorLine="#e0e0e0" className="dividerCategory" />
              <p className="selectorCategory" onClick={handleClickArgentina}>
                Historia General del Arte en la Argentina
              </p>
              <VerticalLine colorLine="#e0e0e0" className="dividerCategory" />
              <p className="selectorCategory" onClick={handleClickHistoricas}>
                Históricas
              </p>
            </div>
          </div>

          {loading ? (
            <>
              <Col className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Col>
            </>
          ) : (
            <div className="NoticiasContainer">
              <>{publicaciones}</>
            </div>
          )}
        </Container>
      </div>
    </section>
  );
}
