import bg from './../../assets/img/Bg.png'
import './style.css'
export default function AcademicRelacionadoCard(
    {
        img = bg,
        nombre = "nombre academico",
        tipo = "tipo de academico",
        disciplina = "disciplina",
        id
    }
) {
    return (
        <a className="AcademicoLink" href={'/academicos/' + id + '?id=' + id} onClick={() => { window.scroll(0, 0); }}>
            <div className='CartaRelacionado'>
                <img src={img} alt={nombre} className="CartaRelacionadoImg" />
                <h5 className='CartaRelacionadoTitulo'>{nombre}</h5>
                <p className='CartaRelacionadoTexto'>{tipo}</p>
                <p className='CartaRelacionadoTexto'>{disciplina}</p>
            </div>
        </a>
    )
}