import React from "react";
import { Col, Row } from "react-bootstrap";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMaximize } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

export default function BgDocumental({ id, titulo, categoria, openTo, linkTo }) {
    return (
        <div id={id} className="bg-documental position-absolute top-0 w-100 h-100 align-items-center justify-content-center flex-column" style={{ display: 'none' }}>
            <Row className="mb-md-3 pb-md-2">
                <Col xs="auto" className="pe-md-1">
                    <Link to={openTo}>
                        <div className="container-icon">
                            <FontAwesomeIcon icon={faWindowMaximize} />
                        </div>
                    </Link>
                </Col>
                <Col xs="auto" className="ps-md-1">
                    <Link to={linkTo}>
                        <div className="container-icon">
                            <FontAwesomeIcon icon={faLink} />
                        </div>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="text-center">
                    {titulo !== undefined && titulo !== '' ? <p className="mb-1">{titulo}</p> : <></>}
                    {categoria !== undefined && categoria !== '' ? <p className="mb-1">{categoria}</p> : <></>}
                </Col>
            </Row>
        </div>
    );
}