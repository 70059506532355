import React from "react";
import "./styles.css";
import { Card } from "react-bootstrap";
import bg from "./../../assets/img/Bg.png";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function NoticiaCard({
  Img = bg,
  dia = "17",
  mes = "OCTUBRE",
  anio = "2022",
  titulo = "Titulo de la noticia",
  tipo = "Tipo Noticia",
  texto = "Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto",
  id = "0",
}) {
  return (
    <Card className="BlogNoticiasCard">
      <Link
        to={"/noticias/" + id + "?id=" + id}
        onClick={() => {
          window.scroll(0, 0);
        }}
      >
        <img className="BlogNoticiasCardImg" alt="Sample" src={Img} />
      </Link>
      <div className="BlogNoticiasCardTextConteiner">
        <div className="BlogNoticiasFechaContainer">
          <div className="d-none d-md-block blueLineNoticia"></div>
          <p className="BlogNoticiasDia">{dia}</p>
          <div>
            <p className="BlogNoticiasMes">
              - {mes} {anio}
            </p>
          </div>
        </div>
        <h1 className="BlogNoticiasTitulo">{titulo}</h1>
        <div className="BlogNoticiasDivider"></div>
        <p className="BlogNoticiasTipo">{tipo}</p>
        {texto !== null ? (
          <>
            <p
              className="d-none d-md-block BlogNoticiasTexto"
              dangerouslySetInnerHTML={{
                __html:
                  texto.length > 180
                    ? texto.substring(0, 180).replace(/\s+$/, "") + "..."
                    : texto,
              }}
            />
          </>
        ) : (
          <>
            <p
              className="d-none d-md-block BlogNoticiasTexto"
              dangerouslySetInnerHTML={{ __html: texto }}
            />
          </>
        )}

        <Link
          className="BlogNoticiasLink"
          to={"/noticias/" + id + "?id=" + id}
          onClick={() => {
            window.scroll(0, 0);
          }}
        >
          <span className="BlogNoticiasMasInfo">
            Mas info
            <FontAwesomeIcon
              icon={faArrowRightLong}
              className="BlogNoticiaiconArrow"
            />
          </span>
        </Link>
      </div>
    </Card>
  );
}
