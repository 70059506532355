import React, { useEffect, useState } from "react";
import "./style.css";
import { REST_URL } from "./../../config/env";
import { Col, Container, Row } from "react-bootstrap";
import IconDivisor from "../../components/IconDivisor";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Instituciones() {
  const [textI, settextI] = useState("");

  useEffect(() => {
    textIntroductorio();
  }, []);

  async function textIntroductorio() {
    await fetch(REST_URL + "/datosControlerOBDA", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((textoI) => {
        settextI(textoI[1].texto_introductorio);
      });
  }
  return (
    <section id="FondoDocumental">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/Bg_hitos.png")}
          alt="Sala de plenario"
        />
        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            Instituciones relacionadas
          </h3>
        </div>
      </article>
      <Container className="py-5">
        <Row className="pt-lg-5">
          <Col md="12">
            <h2 className="title-article text-center mb-0">
              instituciones relacionadas
            </h2>

            <div className="py-3 text-center mb-5">
              <IconDivisor opacity={0.3} width={100} />
              <p className="align-self-end mt-3">{textI}</p>
            </div>
          </Col>
        </Row>
        <Row className="instituciones d-md-none justify-content-center"></Row>
        <Row className="instituciones justify-content-md-center  d-md-flex pt-md-3 pt-lg-0">
          <Col className="px-md-3 col-lg-6 col-md-4 col-12">
            <HashLink to="/fundacion-trabucco#top" className="FundacionLink">
              <Row className="px-md-2">
                <Col xs="12" className="mb-md-3">
                  <img
                    className="w-100"
                    src={require("../../assets/img/Trabucco.png")}
                    alt="Trabucco.png"
                  />
                </Col>
                <Col className="align-self-end mt-3">
                  <h3>fundación trabucco</h3>
                  <p>
                    La Fundación Alberto J. Trabucco fue creado por la Academia
                    Nacional de Bellas Artes en 1991 con fondos provenientes del
                    legado que el distinguido artista, Alberto Trabucco, donó a
                    nuestra institución.
                  </p>
                </Col>
              </Row>
            </HashLink>
          </Col>

          <Col className="px-md-3 col-lg-6 col-md-4 col-12">
            <a
              href="https://www.fundacionfjklemm.org/"
              target="blank"
              className="FundacionLink"
            >
              <Row className="px-md-2">
                <Col xs="12" className="mb-md-3">
                  <img
                    className="w-100"
                    src={require("../../assets/img/KLEMM.png")}
                    alt="KLEMM.png"
                  />
                </Col>
                <Col className="align-self-end mt-3">
                  <h3 className="tituloFundacion">fundación klemm</h3>
                  <p>
                    Desde 1998, la Fundación cuenta con la designación de la
                    Academia Nacional de Bellas Artes como veedora artística y
                    administrativa de la Fundación
                  </p>
                </Col>
              </Row>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
