import { SplideSlide } from "@splidejs/react-splide";
import React, { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import RedirectLink from "../RedirectLink";

import "@splidejs/react-splide/css";

export default function CardInfo({ categoria, titulo, descr, img, linkTo }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <SplideSlide className="card-info-agenda pb-md-5 mb-md-3">
      <Row>
        <Col xs="12" className="mb-md-4">
        {!imageLoaded && (
            <div className="skeleton-image" style={{ height: "200px" , height: 300 , backgroundColor:"gray"  }}></div>
          )}
          <Image
            src={img}
            alt={img}
            className={`w-100 ${!imageLoaded ? "d-none" : ""}`}
            onLoad={handleImageLoad}
            style={!imageLoaded ? { display: "none" } : {}}
          />
        </Col>
        <Col xs="12">
          {categoria !== undefined && categoria !== "" ? (
            <h6 className="mb-md-4">
              <span className="border-title"></span>
              {categoria}
            </h6>
          ) : (
            <></>
          )}
          {titulo !== undefined && titulo !== "" ? (
            <h4 className="mb-0">{titulo}</h4>
          ) : (
            <></>
          )}
        </Col>
        <Col xs="12">
          <hr />
        </Col>
        <RedirectLink
          to={linkTo}
          text={"más información"}
          onClick={() => {
            window.scroll(0, 0);
          }}
        />
      </Row>
    </SplideSlide>
  );
}
