import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import popupImage from '../../assets/img/pop-up-historia.jpeg'
import "./style.css"

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{zIndex: 9999}}
      className="modalHome"
    >
      <img src={popupImage} 
      alt='popup' 
      style={{"cursor":"Pointer"}} 
      onClick={() => window.location.href = '/publicaciones/publicacion?id=64'} >
      </img>
    </Modal>
  );
}

function Popup() {
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setModalShow(true);
  }, []);

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Popup;
