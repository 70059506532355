import React from "react";
import './styles.css'

export default function HorizontalBlueLine() {

    return (
        <div
            className="blueLine"
        ></div>
    )
}