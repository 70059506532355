import React from "react";
import './styles.css'
import { Card } from "react-bootstrap";
import bg from './../../assets/img/Bg.png'

export default function StaffCard({ Nombre = "Nombre",
    sector = "sector",
    mail = "mail@anba.org.ar",
    Img = bg,
}) {

    return (
        <Card className="StaffCard">
            <img
                className="StaffCardImg"
                alt="Sample"
                src={Img}
            />
            <div>

                <h2 className="StaffCardTitulo" id="nombre">
                    {Nombre}
                </h2>
                <h5
                    className="StaffCardsector">
                    {sector}
                </h5>
                <a href={"mailto:" + mail} className="StaffCardMail">
                    {mail}
                </a>

            </div>
        </Card >
    )

}