import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";

export default function RedirectLink({ to, text, fontSize }) {
    if (fontSize !== undefined) {
        return (
            <NavLink to={to} className="redirect-link w-auto" style={{ fontSize: fontSize }}>{text}<FontAwesomeIcon icon={faArrowRight} className="ms-3" /></NavLink>
        );
    } else {
        return (
            <NavLink to={to} className="redirect-link w-auto">{text}<FontAwesomeIcon icon={faArrowRight} className="ms-3" /></NavLink>
        );
    }
}