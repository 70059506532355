import React from "react";
import './styles.css';
import HorizontalBlueLine from '../../components/horizontalBlueLine';
import bg from './../../assets/img/Bg.png'
import numbertotextoMes from "../../utilities/numbertotextMes";
import { Link } from "react-router-dom";

export default function ProxEvent({
    title = "Proximo Evento",
    Img = bg,
    fecha = "1/1/2022",
    epigrafe = 'texto texto texto texto texto texto texto texto...',
    id = 0
}) {

    const fechaFormateada = fecha.split("/");
    const mes = numbertotextoMes(fechaFormateada[1]);

    return (
        <div className="d-none d-md-block ProxEventContainer">
            <Link className="ProxEventLink" to={'/noticias/' + id + '?id=' + id} onClick={() => { window.scroll(0, 0); }}>
                <div className="ProxEventInterPadding">

                    <p className="ProxEventoTitle">{title}</p>
                    <HorizontalBlueLine />
                    <img src={Img} alt="foto proximo evento" className="ProxEventImg" />
                    <span className="ProxEventFecha">{fechaFormateada[0] + " De " + mes + ", " + fechaFormateada[2]}</span>
                    <p className="proxEventTexto" dangerouslySetInnerHTML={{ __html: epigrafe.substring(0, 100) }} />
                </div>
            </Link>
        </div>
    )
}