import React, { useEffect, useState } from "react";
import { Row, Spinner } from "react-bootstrap";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { useParams } from "react-router-dom";
import premios from "../../utilities/Premios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REST_URL } from "../../config/env";
import { Container } from "react-bootstrap";
import bg from "./../../assets/img/Bg.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const PremiosSingle = () => {
  const [textI, settextI] = useState("");
  const [loading, setloading] = useState(true);

  useEffect(() => {
    textIntroductorio();
  }, []);

  async function textIntroductorio() {
    await fetch(REST_URL + "/datosControlerOBDA", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((textoI) => {
        setloading(false);
        settextI(textoI[2].texto_introductorio);
      });
  }

  const { id } = useParams();

  console.log(premios[id]);

  return (
    <section id="fondoDocumental-single">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/Bg_hitos.png")}
          alt="Sala de plenario"
        />

        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            Premios
          </h3>
          <p className="text-uppercase mb-md-3" id="tituloInstitucional">
            {textI}
          </p>
        </div>
      </article>
      <div className="d-md-block">
        <Container className="ContainerFondoDocumentalSingle py-3">
          <div className="BodyFondoDocumental">
            {!loading ? (
              <>
                <img
                  src={require(`../../assets/img/premios/${premios[id].image}`)}
                  alt={premios[id].titulo}
                  className="fondoDocumentalImg"
                />
                <h5 className="FondoDocumentalSubTitulo">PREMIO</h5>
                <div className="FondoDocumentalDivider"></div>
                <h3 className="FondoDocumentalTitulo">
                  {premios[id].tipo} - {premios[id].año}
                </h3>
                <h3
                  style={{
                    fontFamily: "LoraItalic",
                    fontSize: 14,
                    color: "#808080",
                  }}
                >
                  {premios[id].título}
                </h3>

                <h3
                  style={{
                    fontFamily: "LoraItalic",
                    fontSize: 14,
                    color: "#808080",
                  }}
                  className="FondoDocumentalTitulo"
                >
                  {premios[id].artista}
                </h3>

                <div
                  style={{
                    fontFamily: "LoraItalic",
                    fontSize: 14,
                    color: "#808080",
                  }}
                >
                  {premios[id].descripción}
                </div>
                <div
                  style={{
                    fontFamily: "LoraItalic",
                    fontSize: 14,
                    color: "#808080",
                  }}
                >
                  {premios[id].donadoA}
                </div>
                <div className="FondoDocumentalCompartir">
                  <p className="Compartir">COMPARTIR : </p>
                  <FacebookShareButton url={window.location.href}>
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      id="FondoDocumentalIconFacebook"
                    />
                  </FacebookShareButton>
                  <TwitterShareButton url={window.location.href}>
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="FondoDocumentalIcon"
                    />
                  </TwitterShareButton>
                </div>
              </>
            ) : (
              <>
                <Row className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Row>
                <h5 className="FondoDocumentalSubTitulo">PREMIO</h5>
                <div className="FondoDocumentalDivider"></div>
                <h3 className="FondoDocumentalTitulo">CARGANDO...</h3>
                <p className="FondoDocumentalDescripcion">
                  Cargando descripcion...
                </p>
                <div className="FondoDocumentalCompartir">
                  <p className="Compartir">COMPARTIR : </p>
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    id="FondoDocumentalIconFacebook"
                  />
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="FondoDocumentalIcon"
                  />
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="FondoDocumentalIcon"
                  />
                </div>
              </>
            )}
          </div>

          <HashLink
            to="/fundacion-trabucco#top"
            className="d-none d-md-block LinkVolver"
          >
            <div className="VolverFondoDocumental">
              <div className="VolverContainer">
                <p className="VolverDocumentalYColeccion">
                  VOLVER A
                  <br />
                  FUNDACION TRABUCCO
                </p>
              </div>
            </div>
          </HashLink>
        </Container>
      </div>
    </section>
  );
};

export default PremiosSingle;
