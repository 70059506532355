import React from "react";
import "./style.css";
import { Container } from "react-bootstrap";
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FacebookShareButton, TwitterShareButton } from "react-share";

export default function Estatuto() {
  return (
    <>
      <section id="estatuto" onLoad={window.scroll(0, 0)}>
        <article className="portada">
          <img
            className="imagenPortada"
            src={require("../../assets/img/estatuto.png")}
            alt="Portada Agenda"
          />
          <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
            <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
              ESTATUTO Y REGLAMENTO INTERNO
            </h3>
            <p className="subTituloPortada">
              INICIO /{" "}
              <span className="subTituloPortadaSpan">INSTITUCIONAL</span>
            </p>
          </div>
        </article>

        <Container className="py-5">
          <span className="notFoundtitulo">ESTATUTO Y REGLAMENTO INTERNO</span>
          <div className="divider404"></div>

          <p className="textEjemeplo">
            La Academia Nacional de Bellas Artes, creada por Decreto del Poder
            Ejecutivo Nacional el 1 de julio de 1936, de acuerdo con el Decreto
            Nro. 4362 del Poder Ejecutivo Nacional del 30 de noviembre de 1955,
            ha sido autorizada para funcionar con carácter de persona jurídica
            por Decreto del Poder Ejecutivo Nacional Nro. 11.636 del 25 de
            septiembre de 1957.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 1.-</strong> La Asociación civil denominada Academia
            Nacional de Bellas Artes, con personería jurídica otorgada por
            decreto Nº 11.636 del 25 de septiembre de 1957, está constituida por
            los señores académicos designados antes de ahora, y por los que se
            designen en el futuro de acuerdo con lo establecido en estos
            estatutos. Tiene por fin contribuir al desarrollo de las bellas
            artes en todas sus ramas: artes visuales, música, arquitectura y
            urbanismo, historia y crítica de arte, y acción cultural; realizar
            estudios e investigaciones divulgando sus resultados; promover la
            conservación y el acrecentamiento de los bienes, de propiedad
            pública o privada, que forman el patrimonio artístico de la
            comunidad; formular planes de acción cultural; asesorar al Gobierno
            de la Nación y a los de las provincias y municipalidades, y a otras
            instituciones culturales públicas o privadas; proponer proyectos de
            leyes y reglamentaciones; y apoyar toda manifestación relacionada
            con sus actividades específicas.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 2.-</strong> El domicilio legal de la Academia estará
            en la ciudad de Buenos Aires. Su patrimonio estará constituido por
            los bienes que posee en la actualidad y los que adquiera en el
            futuro por compra, herencia, legado o donación o por cualquier otro
            título, así como por las rentas que sus bienes produzcan, y por toda
            otra entrada lícita vinculada con los propósitos que persigue.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 3.-</strong> La Academia estará integrada por treinta y
            cinco académicos de número que se hayan distinguido en las distintas
            especialidades de las artes a que se refiere la enumeración delart.
            1 y ocuparán el sitial correspondiente. Los académicos de número
            deberán ser argentinos nativos, o por opción, o naturalizados, o
            extranjeros que hayan permanecido en el país durante más de diez
            años con la intención declarada de radicarse definitivamente en él.
          </p>

          <p className="textEjemeplo">
            <strong>Art. 4.- </strong>El Académico de Número podrá por causa
            mayor, salud o haber alcanzado los 80 años de edad, solicitar ser
            promovido a la categoría de Académico Emérito. El sitial quedará
            vacante.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 5.- </strong>A la misma categoría de Académico Emérito
            podrá ser promovido el académico de número que, por causa de fuerza
            mayor, tenga un impedimento permanente para cumplir con sus
            funciones. La promoción será decidida a propuesta de la Mesa
            Directiva en reunión plenaria por el voto positivo de al menos dos
            tercios de los académicos de número que se encuentren presentes.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 6.- </strong>La Academia podrá designar académicos
            delegados que la representen en cualquier punto de la República,
            fuera de la ciudad de Buenos Aires. Los académicos delegados deberán
            reunir las mismas calidades requeridas para la designación de los
            académicos de número.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 7.- </strong>Podrá también designar académicos
            correspondientes en el exterior a argentinos radicados
            definitivamente en el país de que se trate o a personalidades
            extranjeras que se hayan interesado por los problemas de la cultura
            argentina o tengan la intención manifiesta de hacerlo.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 8.- </strong>Los académicos de número, delegados y
            correspondientes serán designados en una sesión ordinaria mensual de
            la Academia en cuyo orden del día haya sido incluido el punto
            respectivo. Las designaciones se harán por el voto positivo de al
            menos dos tercios de los académicos de número que se encuentren
            presentes.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 9.- </strong>La Academia tendrá un presidente, un
            vicepresidente, un secretario general, un prosecretario de actas, un
            tesorero y un protesorero que formarán la Mesa Directiva. Durarán
            tres años en sus funciones pudiendo ser reelectos una vez en forma
            consecutiva, o nuevamente en el mismo cargo, pero sólo después de
            transcurrido un período igual al de su desempeño. A la terminación
            de sus funciones podrán ser elegidos libremente en cualquier otro
            cargo de la Mesa Directiva.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 10.- </strong> La elección de los miembros de la Mesa
            Directiva será hecha en una sesión especial de la Academia convocada
            al efecto al término de cada período de tres años, que se efectuará
            en el curso del mes de octubre correspondiente, comenzando el
            mandato a partir del día 1º de enero del año siguiente.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 11.- </strong> La sesión plenaria especial a que se
            refiere el artículo anterior deberá realizarse con la presencia
            mínima de la mitad más uno de los académicos de número. La elección
            de todos y cada uno de los miembros de la Mesa Directiva deberá ser
            hecha por el voto de la mitad más uno de los académicos en ejercicio
            que se encuentren presentes en la sesión especial. En el caso de que
            en dos votaciones sucesivas no se logre esta mayoría, la elección
            deberá ser hecha entre los dos académicos que hayan obtenido mayor
            cantidad de votos en la segunda votación. El presidente tendrá doble
            voto en caso de empate. La sesión especial deberá desarrollarse en
            una sola jornada, sin interrupción.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 12.- </strong> A los efectos de la sesión especial a la
            que refiere el Artículo 9º se considerará que no están en ejercicio
            los académicos de número que hayan faltado sin aviso a las tres
            reuniones consecutivas anteriores a dicha sesión. Todo académico de
            número que no estuviere en ejercicio recuperará esa condición
            asistiendo a tres consecutivas de las reuniones plenarias previstas
            en el artículo 20, sin que pueda computársele su asistencia a la
            sesión especial para tales efectos.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 13.- </strong> El cargo de académico es un título
            honorífico y vitalicio. Los académicos no pueden ser suspendidos ni
            separados de sus funciones, salvo en circunstancias excepcionales
            que afecten a su buen nombre y honor y al buen nombre y honor de la
            Academia. La separación de un académico sólo podrá ser resuelta por
            el voto unánime de los académicos de número en ejercicio, convocados
            especialmente al efecto y previa audiencia del afectado, a quien se
            le garantizará el derecho de defensa.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 14.- </strong> Después de diez (10) plenarios
            ordinarios consecutivos de inasistencia sin que mediara pedido de
            licencia por causa justificada, todo académico de número podrá ser
            cambiado a la categoría de emérito, previa comunicación escrita de
            la Mesa Directiva. El cambio será resuelto en sesión plenaria
            ordinaria, a propuesta de la Mesa Directiva. El sitial quedará
            vacante. El período máximo de licencia a otorgarse será de un año
            calendario de duración, según las condiciones que establezca el
            reglamento interno.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 15.- </strong> El presidente representará a la Academia
            en todos sus actos jurídicos y actividades públicas. Tendrá voz y
            voto en sus deliberaciones, y doble voto en caso de empate, menos
            cuando se trate de votaciones que importen la elección de académicos
            de número, delegados o correspondientes.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 16.- </strong> A falta del presidente ejercerá la
            presidencia el vicepresidente, y a falta del presidente y del
            vicepresidente, ejercerá la presidencia provisionalmente el
            secretario general y, en su defecto, el académico más antiguo,
            prefiriéndose entre los de mayor antigüedad, el de mayor edad.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 17.- </strong> El reglamento interno que dictará la
            Academia establecerá la forma y oportunidades en que el
            vicepresidente reemplazará al presidente en caso de ausencia
            temporaria, por enfermedad o por cualquier otra causa. Cuando la
            ausencia sea definitiva por renuncia, impedimento insalvable, o
            fallecimiento del presidente, el vicepresidente lo reemplazará hasta
            el final del período para el cual fue nombrado. Cuando el secretario
            general o el académico más antiguo deban asumir la presidencia,
            según lo previsto en el artículo anterior, lo harán al solo objeto
            de convocar a elecciones para cubrir los cargos vacantes hasta la
            terminación del período correspondiente.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 18.- </strong> Sin perjuicio de los reemplazos
            naturales, presidente por vicepresidente, secretario general por
            prosecretario de actas, tesorero por protesorero, toda vacante
            definitiva que se produzca en la Mesa Directiva con una anterioridad
            mayor a los últimos seis (6) meses de la gestión, será llenada hasta
            la terminación del período por elección del reemplazante en una
            sesión especial convocada al efecto y en las demás condiciones
            establecidas en el artículo 10.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 19.- </strong> Los ex presidentes de la Academia serán
            miembros natos de la Mesa Directiva con voz y voto cuando concurran
            voluntariamente a sus reuniones.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 20.- </strong> La Academia se reunirá ordinariamente
            por lo menos una vez al mes calendario desde el 1º de marzo hasta el
            31 de diciembre de cada año y, extraordinariamente, cuando la
            convoque la Mesa Directiva o lo pidan por escrito cinco académicos
            de número como mínimo. En este último caso la convocatoria no podrá
            demorarse más de ocho días hábiles. Las citaciones se efectuarán por
            circulares enviadas al domicilio de los académicos con cinco días de
            anticipación.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 21.- </strong> Fuera de los casos determinados por este
            estatuto en que se establece un quorum especial, la Academia podrá
            sesionar con los académicos en ejercicio que concurran, citados al
            efecto, pero sólo podrá tomar decisiones con el voto de la mitad más
            uno de los miembros en ejercicio.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 22.- </strong> La Academia sólo podrá tratar en sus
            sesiones los asuntos comprendidos en el orden del día, salvo que dos
            tercios de los académicos presentes decidan tratar casos urgentes.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 23.- </strong> El reglamento interno fijará el número
            de comisiones en que se dividirá el trabajo de la Academia así como
            su denominación y funciones. En la primera reunión del año que cada
            comisión realice procederá a elegir a uno de sus integrantes como
            secretario para que convoque a la comisión y presida sus
            deliberaciones durante el año calendario correspondiente, con voz y
            doble voto en caso de empate en todas sus decisiones.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 24.- </strong> En la primera sesión ordinaria de cada
            año que realice la Academia, durante el mes de marzo, se considerará
            la Memoria, el Balance y el Inventario correspondientes al ejercicio
            del año inmediatamente anterior, que deberán ser aprobados por la
            mitad más uno de los académicos de número concurrentes a la sesión
            respectiva.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 25.- </strong> La Academia podrá disponer libremente a
            su solo arbitrio de los fondos y recursos de todo origen con que
            cuente, de acuerdo con el cálculo de gastos y recursos que formule
            la Mesa Directiva. El cálculo de gastos y recursos podrá modificarse
            todas las veces que la Mesa Directiva lo considere necesario para el
            mejor cumplimiento de sus fines, dando cuenta al plenario de la
            Academia en la primera sesión siguiente que realice.
          </p>
          <p className="textEjemeplo">
            <strong>Art. 26.- </strong> Este estatuto podrá ser reformado por
            decisión de la Academia adoptada en una sesión especial convocada al
            efecto con el quorum y la mayoría establecidos en el{" "}
            <strong>art. 11°. </strong>
          </p>
          <p className="textEjemeplo">
            <strong>Art. 27.- </strong> Dentro del término de noventa días
            hábiles consecutivos a la fecha de aprobación del presente estatuto,
            la Academia introducirá en el reglamento interno las disposiciones
            que sean necesarias para su debido cumplimiento.
          </p>

          <div className="Vinieta">
            <ul>
              <li className="mbvinieta">
                <span className="textInterno">
                  Aprobado por la Inspección General de Justicia con fecha 4 de
                  diciembre de 1989. (Expediente C <br /> 1596/3425/359.421).
                  Resolución Nº 000986.
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada por la Inspección General de Justicia
                  con fecha 19 de abril de 1996. (Expediente <br />{" "}
                  C.359421/3425). Resolución Nº 000360
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada por la Inspección General de Justicia
                  con fecha 30 de julio de 2007. (Expediente C <br />
                  359421/3425/59802). Resolución Nº 000560
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada por la Inspección General de Justicia
                  con fecha 16 de enero de 2018. (Expediente C <br />{" "}
                  359421/7766304). Resolución Nº 000097
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada por la Inspección General de Justicia
                  con fecha 29 de agosto de 2018. (Resolución Nº <br /> 0001803)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en plenario especial del 17 de junio de
                  2021. Pendiente de aprobación de la Inspección <br /> General
                  de Justicia.
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada por la Inspección General de Justicia
                  con fecha 7 de julio de 2022. (Expediente No <br />{" "}
                  359421/9360983). Resolución No 0000775.
                </span>
              </li>
            </ul>
          </div>

          <span className="notFoundtitulo">
            REGLAMENTO INTERNO <br />
          </span>
          <span className="notFoundtitulo" style={{ color: "black" }}>
            De la Mesa Directiva
          </span>

          <p className="textEjemeplo">
            <strong>Art. 1.- </strong> La Mesa Directiva estará integrada y
            funcionará de acuerdo con lo establecido en los artículos 9, 10, 11,
            12, 16, 17, 18 y 19 de los estatutos.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 2.-</strong> En el caso previsto en el artículo 17 la
            sesión plenaria especial de la Academia para llenar la vacante
            producida deberá realizarse dentro del plazo de sesenta días hábiles
            a partir del día en que se haya producido la vacante.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 3.-</strong> La Mesa Directiva se reunirá al menos una
            vez por mes desde el mes de marzo hasta el mes de diciembre de cada
            año, con los miembros que concurran. En la primera reunión que
            realice cada año, fijará el calendario anual de reuniones, quedando
            de hecho notificados y citados todos sus miembros. El calendario
            podrá ser modificado ocasionalmente por decisión del Presidente. En
            tal caso los señores miembros de la Mesa Directiva deberán ser
            citados especialmente.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 4.-</strong> Sin perjuicio de lo dispuesto en el
            artículo anterior, el Presidente podrá citar a la Mesa Directiva a
            reunirse cuando lo estime conveniente.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 5.-</strong> Las resoluciones de la Mesa Directiva se
            adoptarán por mayoría de votos de los señores miembros presentes. El
            Presidente tendrá doble voto en todos los casos de empate.
          </p>

          <span className="notFoundtitulo">
            Del Presidente y del Vicepresidente <br />
          </span>

          <p className="textEjemeplo">
            <strong> Art. 6.-</strong> El Presidente ejercerá sus funciones en
            la forma prevista en el artículo 15, con todas las facultades que
            los estatutos le confieren.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 7.-</strong>En caso de ausencia o impedimento
            temporarios del Presidente, sea cual fuera la causa, ejercerá sus
            funciones el Vice Presidente, y en caso de ausencia o impedimento de
            éste, el Secretario, sin que sea necesario acreditar ante terceros
            en cada caso la delegación efectuada. Si la ausencia fuera mayor de
            treinta días hábiles el Presidente deberá solicitar licencia a la
            Academia reunida en Sesión Plenaria.
          </p>

          <span className="notFoundtitulo">
            Del Secretario General <br />
          </span>

          <p className="textEjemeplo">
            <strong> Art. 8.-</strong> Colaborará con el Presidente en todos los
            asuntos que interesen a la Academia.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 9.-</strong> Ejercerá la presidencia provisional de la
            Academia en los casos previstos en los artículos 16 y 17 de los
            estatutos.
          </p>
          <span className="notFoundtitulo">
            Del Prosecretario de Actas <br />
          </span>

          <p className="textEjemeplo">
            <strong> Art. 10.-</strong> Reemplazará al Secretario General en
            caso de ausencia o impedimento temporario de éste.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 11.-</strong> Redactará las actas de las sesiones
            plenarias de la Academia.
          </p>

          <span className="notFoundtitulo">
            Del Tesorero <br />
          </span>
          <p className="textEjemeplo">
            <strong> Art. 12.-</strong> Tendrá a su cargo las tareas que
            corresponden a las actividades económicas y financieras de la
            Academia, y las gestiones administrativas.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 13.-</strong> Durante el mes de marzo de cada año
            presentará a la Mesa Directiva el proyecto de cálculo de gastos y
            recursos para el año en curso. Toda vez que lo estime conveniente,
            durante el transcurso del ejercicio, propondrá las modificaciones
            que correspondan.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 14.-</strong> En el mes de diciembre de cada año
            presentará a la Mesa Directiva el proyecto de Balance General de la
            Academia y rendiciones de cuentas administrativas.
          </p>
          <span className="notFoundtitulo">
            Del ProTesorero
            <br />
          </span>
          <p className="textEjemeplo">
            <strong> Art. 15.-</strong> Reemplazará al Tesorero en caso de
            ausencia temporaria de aquel, sea cual fuere la causa, quedando
            desde ya habilitado para ejercer las funciones respectivas.
          </p>
          <span className="notFoundtitulo">
            De la firma de actos e instrumentos jurídicos
            <br />
          </span>

          <p className="textEjemeplo">
            <strong> Art. 16.-</strong> El Presidente ejerce la representación
            legal de la Academia de conformidad con el artículo 15 del estatuto
            y firma con el Secretario General o con el Pro Secretario de actas
            en caso de ausencia de aquel, la Memoria Anual. El Plenario podrá
            resolver, al autorizar actos de disposición o administración
            extraordinaria, que otro miembro de la Mesa Directiva suscriba
            conjuntamente con el Presidente las escrituras correspondientes.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 17.-</strong> Los cheques, órdenes de pago y
            documentos bancarios serán firmados por dos cualesquiera,
            indistintamente, de los miembros de la Mesa Directiva.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 18.-</strong> Las rendiciones de cuentas y balances
            anuales serán firmados por todos los miembros de la Mesa Directiva.
          </p>

          <span className="notFoundtitulo">
            De las sesiones plenarias
            <br />
          </span>

          <p className="textEjemeplo">
            <strong> Art. 19.-</strong> Se llevarán a cabo en las condiciones y
            en la forma establecidas en los estatutos. El Pro Secretario de
            Actas redactará las actas de las sesiones que se transcribirán en el
            libro respectivo una vez aprobadas por la Academia en la sesión
            plenaria siguiente
          </p>

          <p className="textEjemeplo">
            <strong> Art. 20.-</strong> En caso de ausencia del Pro Secretario
            de Actas, sea cual fuere la causa, la Academia designará Pro
            Secretario de Actas ad hoc a uno cualquiera de los Académicos
            presentes.
          </p>

          <span className="notFoundtitulo">
            De las comisiones académicas
            <br />
          </span>
          <p className="textEjemeplo">
            <strong> Art. 21.-</strong>Las tareas de la Academia se
            desarrollarán en las siguientes comisiones: 1 Artes Visuales
            cinematografía, dibujo, escultura, fotografía, grabado, pintura,
            textil y otros soportes; 2 Arquitectura, Diseño y Urbanismo; 3
            Música; 4 Historia y Crítica del Arte; 5 Archivo, Biblioteca,
            Investigación y Documentación del Patrimonio Artístico; 6
            Publicaciones y Conferencias; 7 Interpretación y Reglamento; 8
            Acción Cultural; 9 Recursos Financieros. A su solicitud, los señores
            Académicos podrán formar parte de una o más comisiones.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 22.-</strong> La Mesa Directiva podrá crear otras
            comisiones transitorias o permanentes, dando cuenta al Plenario de
            la Academia en la primera reunión siguiente que realice.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 23.-</strong> Todas y cada una de las comisiones
            permanentes se reunirán, por separado, durante el mes de marzo de
            cada año, para elegir al Secretario de la comisión, que deberá ser
            académico de número, presidirá sus reuniones y tendrá doble voto en
            los casos de empate.
          </p>
          <span className="notFoundtitulo">
            De los nuevos Académicos
            <br />
          </span>
          <p className="textEjemeplo">
            <strong> Art. 24.-</strong> La designación de nuevos Académicos, de
            Número, Delegados y Correspondientes, se hará en la forma y
            condiciones establecidas en el estatuto. Todo Académico tiene el
            derecho de proponer candidatos en las disciplinas que determina el
            Estatuto. El procedimiento a seguir será el siguiente: el Académico
            proponente efectuará su propuesta ante la Mesa Directiva, munida del
            curriculum vitae del candidato, avalada por 4 (cuatro) firmas de
            Académicos de Número. La Mesa Directiva sin otro recaudo la girará a
            dictamen de la comisión que corresponda, o, si fuese necesario, a
            una comisión designada al efecto. Dentro del mes siguiente dicha
            comisión someterá su dictamen al Plenario en cuyo orden del día haya
            sido incluido el tema. Se votará en el Plenario si se da por
            aceptada, requiriéndose el voto de al menos dos tercios de los
            Académicos presentes para su aceptación. La propuesta deberá ser
            considerada en la próxima sesión plenaria con el quorum de la mitad
            más uno de los Académicos en ejercicio. El candidato será designado
            si obtiene el voto de al menos dos tercios de los Académicos
            presentes.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 25.-</strong> En todos los casos de designación de
            nuevos Académicos sean de Número, Delegados o Correspondientes, las
            presentaciones respectivas deberán ser consideradas por la Academia
            en una sesión Plenaria y resueltas en la sesión Plenaria siguiente.
          </p>

          <span className="notFoundtitulo">
            De la asistencia
            <br />
          </span>
          {/* // */}
          <p className="textEjemeplo">
            <strong> Art. 26.-</strong> A los efectos de lo dispuesto por los
            artículos 12° y 14° del Estatuto, sólo serán consideradas para su
            justificación por la Mesa Directiva las inasistencias avisadas a la
            misma mediante telegrama, correo electrónico, mensaje de texto u
            otro medio análogo que permita su registro gráfico, recibidas hasta
            el día inmediatamente anterior al de la sesión ordinaria o especial
            de que se trate.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 27.-</strong> Los avisos recibidos por cualquier medio
            en el mismo día de la sesión ordinaria o especial de que se trate
            sólo serán considerados para su justificación en caso de manifiesta
            causa de fuerza mayor.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 28.-</strong> Serán consideradas justificadas por
            motivos de salud sin certificación médica, hasta tres inasistencias
            a plenarios ordinarios consecutivos. Transcurrido ese lapso y de no
            hacerse presente el cuarto plenario, la Mesa Directiva considerará
            que a partir de la primera de las inasistencias consecutivas
            incurridas es de aplicación lo dispuesto por el artículo 14° del
            Estatuto.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 29.-</strong> El período máximo de licencia a otorgar
            por la Mesa Directiva con comunicación al plenario ordinario
            siguiente será de un año calendario de duración, debiendo el
            interesado exponer en su solicitud las razones que avalan su pedido.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 30.-</strong> Las solicitudes de prórrogas de la
            licencia a que hace mención el artículo anterior serán incluidas en
            el orden del día de la sesión plenaria ordinaria siguiente al pedido
            y resueltas por mayoría simple de votos de los Académicos de Número
            presentes en la misma.
          </p>

          <span className="notFoundtitulo">
            De los Académicos Delegados
            <br />
          </span>

          <p className="textEjemeplo">
            <strong> Art. 31.-</strong> Los Académicos Delegados que por razones
            de cambio de domicilio u otra causa mayor, no puedan o no deseen
            continuar representando a la Academia en aquellos puntos para los
            que fueron designados, podrán solicitar ser dispensados de sus
            responsabilidades, pasando a revistar como Académicos Delegados
            Honorarios.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 32.-</strong> En caso de darse los supuestos de cambio
            de domicilio u otra causa mayor a que refiere el artículo 31° y no
            mediara solicitud de dispensa por parte del Académico Delegado, el
            mismo podrá ser cambiado a la situación de honorario, previa
            comunicación escrita de la Mesa Directiva.
          </p>
          <p className="textEjemeplo">
            <strong> Art. 33.-</strong> La dispensa y cambio en la situación a
            que refieren los artículos 31° y 32° serán aprobados a propuesta de
            la Mesa Directiva en reunión plenaria en cuyo orden del día haya
            sido incluido el punto respectivo con el voto positivo de al menos
            la mitad más uno de los Académicos de Número que se encuentren
            presentes.
          </p>

          <span className="notFoundtitulo">
            De la modificación del reglamento
            <br />
          </span>

          <p className="textEjemeplo">
            <strong> Art. 34.-</strong> Art. 34.- Este reglamento podrá ser
            modificado por decisión de la Academia adoptada en una sesión
            Plenaria convocada especialmente al efecto. La propuesta de
            modificación deberá ser formulada por cinco señores Académicos como
            mínimo.
          </p>

          <div className="Vinieta">
            <ul>
              <li className="mbvinieta">
                <span className="textInterno">
                  Aprobado en sesión plenaria de la Academia el día 19 de abril
                  de 1990 (Acta Nº 480)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en sesión Plenaria de la Academia el día
                  19 de octubre de 1995 (Acta Nº 542)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en sesión Plenaria de la Academia el 20
                  de julio de 2000 (Acta Nº 590)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en sesión Plenaria de la Academia el 19
                  de octubre de 2006 (Acta Nº 657)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en sesión Plenaria de la Academia el 15
                  de diciembre de 2011 (Acta Nº 712)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en sesión Plenaria de la Academia el 19
                  de abril de 2012 (Acta Nº 714)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en sesión Plenaria de la Academia el 17
                  de julio de 2014 (Acta Nº 740)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en sesión Plenaria de la Academia el 20
                  de abril de 2017 (Acta Nº 772)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en sesión Plenaria de la Academia el 19
                  de abril de 2018 (Acta Nº 785)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en sesión Plenaria de la Academia el 25
                  de abril de 2019 (Acta Nº 799)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en sesión Plenaria de la Academia el 21
                  de noviembre de 2019 (Acta Nº 807)
                </span>
              </li>
              <li className="mbvinieta">
                <span className="textInterno">
                  Modificación aprobada en sesión Plenaria de la Academia el 21
                  de septiembre de 2023 (Acta Nº 846)
                </span>
              </li>
            </ul>
          </div>

          <div className="compartirEstatuto">
            <span>COMPARTIR</span>
            <span>:</span>
            <FacebookShareButton url={window.location.href}>
              <FontAwesomeIcon
                icon={faFacebookF}
                id="FondoDocumentalIconFacebook"
              />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <FontAwesomeIcon
                icon={faTwitter}
                className="FondoDocumentalIcon"
              />
            </TwitterShareButton>
          </div>
        </Container>
      </section>
    </>
  );
}
