import React from "react";
import './style.css';


export default function ImgPopup(props) {

    return (
        <div className="popup">
            <div className="popup-content">
                <img src={props.src} alt={props.alt} />
                <button onClick={props.onClose}>X</button>
            </div>
        </div>
    );
}