import React, { useEffect, useState } from "react";
import "./styles.css";
import { Container } from "react-bootstrap";
import bg from "./../../assets/img/Bg.png";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { REST_URL } from "../../config/env";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import "./../../assets/css/ckeditor.css";

export default function PublicacionSingle() {
  const [publicacion, setpublicacion] = useState();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    buscarDatosPublicaciones();
    console.log(publicacion);
  }, []);

  async function buscarDatosPublicaciones() {
    const valores = new URLSearchParams(window.location.search);
    const id = valores.get("id");

    await fetch(REST_URL + "/Publicacion/" + id)
      .then((Response) => Response.json())
      .then((rawJson) => {
        console.log(rawJson[0]);
        setpublicacion(rawJson[0]);
        setloading(true);
      });
  }

  return (
    <section id="NoticasSinlge">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/TAPApublicaciones.png")}
          alt="Sala de plenario"
        />
        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            PUBLICACIONES
          </h3>
          {loading ? (
            <>
              <p className="subTituloPortada">{publicacion.titulo}</p>
            </>
          ) : (
            <>
              <p className="subTituloPortada">Cargando...</p>
            </>
          )}
        </div>
      </article>
      <div className="d-md-block">
        <Container className="ContainerFondoDocumentalSingle py-3">
          {loading ? (
            <>
              <div className="BodyFondoDocumental">
                <img
                  id="imagenNoticia"
                  src={
                    "https://anba-admin.depcsuite.com/publicaciones_archivos/imagenes/" +
                    publicacion.imagen_publicacion
                  }
                  alt={publicacion.titulo}
                  className="fondoDocumentalImg"
                />
                <div id="noticia">
                  <div className="PublicacionSingleTitulosContainer">
                    <h5 className="FondoDocumentalSubTitulo">
                      {publicacion.categoria}
                    </h5>
                    <h5 className="FondoDocumentalSubTitulo">
                      {publicacion.fecha_publicacion}
                    </h5>
                  </div>
                  <div className="FondoDocumentalDivider"></div>
                  <h3 className="FondoDocumentalTitulo">
                    {publicacion.titulo}
                  </h3>
                  <h3 className="PublicacionSingleSubTitulo">
                    {publicacion.subtitulo}
                  </h3>
                  <p
                    className="FondoDocumentalDescripcion ck-content"
                    dangerouslySetInnerHTML={{
                      __html: publicacion.descripcion,
                    }}
                  />
                </div>
                <div className="PublicacionSingleCompartir">
                  <Link
                    className="PublicacionSingleLink"
                    to={"/publicaciones"}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Volver a Publicaciones
                  </Link>
                  <div className="PublicacionSingleCompatirContainer">
                    <p className="Compartir">COMPARTIR : </p>
                    <FacebookShareButton url={window.location.href}>
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        id="FondoDocumentalIconFacebook"
                      />
                    </FacebookShareButton>
                    <TwitterShareButton url={window.location.href}>
                      <FontAwesomeIcon
                        icon={faTwitter}
                        className="FondoDocumentalIcon"
                      />
                    </TwitterShareButton>
                  </div>
                </div>
              </div>

              <div className="d-md-block VolverFondoDocumental mt-2">
                <div className="DescargarPublicacionContainer">
                  <p className="DescargarPublicacion">Descargar Publicación</p>
                  <div className="DescargarPublicacionDivider"></div>
                  <img
                    className="DescargarPublicacionImagen"
                    src={
                      "https://anba-admin.depcsuite.com/publicaciones_archivos/imagenes/" +
                      publicacion.imagen_publicacion
                    }
                    alt={publicacion.titulo}
                  />
                  <a
                    className="linkPdf"
                    target="_BLANK"
                    href={
                      publicacion.archivo_publicacion.includes("https")
                        ? publicacion.archivo_publicacion
                        : "https://anba-admin.depcsuite.com/publicaciones_archivos/archivos/" +
                          publicacion.archivo_publicacion
                    }
                  >
                    <p className="DescargarPublicacionArchivo">
                      {publicacion.titulo + ".pdf"}
                    </p>
                  </a>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="BodyFondoDocumental">
                <img
                  src={bg}
                  alt="Cargando..."
                  className="fondoDocumentalImg"
                />
                <div className="PublicacionSingleTitulosContainer">
                  <h5 className="FondoDocumentalSubTitulo">
                    CATEGORIA DE LA PUBLICACIONES
                  </h5>
                  <h5 className="FondoDocumentalSubTitulo">fecha</h5>
                </div>
                <div className="FondoDocumentalDivider"></div>
                <h3 className="FondoDocumentalTitulo">CARGANDO...</h3>
                <h3 className="PublicacionSingleSubTitulo">CARGANDO...</h3>
                <p className="FondoDocumentalDescripcion">
                  Cargando descripcion...
                </p>
                <div className="PublicacionSingleCompartir">
                  <Link
                    className="PublicacionSingleLink"
                    to={"/publicaciones"}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Volver a Publicaciones
                  </Link>
                  <div className="PublicacionSingleCompatirContainer">
                    <p className="Compartir">COMPARTIR : </p>
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      id="FondoDocumentalIconFacebook"
                    />
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="FondoDocumentalIcon"
                    />
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="FondoDocumentalIcon"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Container>
      </div>
    </section>
  );
}
