import React, { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import './style.css'
import ResultadoBuscador from "../../components/resultadoBuscador";
import { REST_URL, REST_URL_IMAGES } from './../../config/env'

export default function BuscadorHome() {


    const [buscar, setbuscar] = useState(false);
    const [noticias, setNoticias] = useState();
    const [academicos, setacademicos] = useState();
    const [loading, setloading] = useState(true);
    const [resultados, setresultados] = useState();

    useEffect(() => {
        createNoticias();
        createAcademicos();
    }, []);

    async function createNoticias() {

        await fetch(REST_URL + '/agenda', {
            'mode': 'cors',
            'retry-After': '3600',
            'headers': {
                'Access-Control-Allow-Origin': '*',
            }
        }).then((response) => response.json()).then((rawJson) => {

            setNoticias(rawJson);
        })
    }

    async function createAcademicos() {

        await fetch(REST_URL + '/academicos', {
            'mode': 'cors',
            'retry-After': '3600',
            'headers': {
                'Access-Control-Allow-Origin': '*',
            }
        }).then((response) => response.json()).then((rawJson) => {

            setacademicos(rawJson);
            setloading(false);
        })
    }


    const handlebuscarhome = (event) => {

        setbuscar(true);

        console.log(noticias);
        let valorBusqueda = event.target.value;
        const regex = new RegExp(valorBusqueda, 'i');

        let noticiasTotales = noticias;
        let academicosTotales = academicos;
        let arrayAux = [];

        //buscar en noticias
        noticiasTotales.map(noticia => {

            if (regex.test(noticia.titulo)) {
                return arrayAux.push(noticia);

            }
            if (regex.test(noticia.descripcion)) {
                return arrayAux.push(noticia);
            }

            return 0;
        })
        //buscar en academicos.

        academicosTotales.map(academico => {
            if (regex.test(academico.nombre)) {
                return arrayAux.push(academico);

            }
            if (regex.test(academico.tipo)) {
                return arrayAux.push(academico);
            }

            return 0;
        })

        let resultadosArray = [];

        if (valorBusqueda !== "") {

            arrayAux.map(resultado => {

                if (resultado.nombre) {

                    return (resultadosArray.push(
                        <ResultadoBuscador
                            id={resultado.id}
                            titulo={resultado.nombre}
                            tipo={resultado.tipo}
                            Img={REST_URL_IMAGES + "/academicos_imagenes/" + resultado.imagen}
                            tipoCarta={false}
                        />));
                }
                else {

                }
                return (resultadosArray.push(
                    <ResultadoBuscador
                        id={resultado.id}
                        titulo={resultado.titulo}
                        tipo={resultado.categoria}
                        Img={"https://anba-admin.depcsuite.com/agenda_imagenes/" + resultado.imagen}
                        texto={resultado.descripcion}
                        tipoCarta={true}
                    />));

            });
        }


        setresultados(resultadosArray);

    }
    return (
        <seccion id="buscador">
            <article className='portada'>
                <img
                    className='imagenPortada'
                    src={require('../../assets/img/404.png')}
                    alt='frente ANBA'
                />
                <div className='position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center'>
                    <h3 className='text-uppercase mb-md-3' id='tituloAcademicos'>BUSCADOR</h3>
                    <p className='subTituloPortada'>INICIO / <span className='subTituloPortadaSpan'>BUSCADOR</span></p>
                </div>
            </article>

            <div className="d-none d-md-block">
                <Container className="py-3">
                    <div className=" container-button-buscador">
                        <input type="text"
                            className="input-buscador"
                            onChange={handlebuscarhome}
                        />
                        <Button type="button" variant="secondary" className="button-buscador">
                            Buscar
                        </Button>
                    </div>
                </Container>

                <div className="container py-3 resultados-buscador" key={1}>

                    {
                        buscar ?
                            <>
                                {
                                    loading ?
                                        <>
                                            <p>Buscando..</p>
                                        </>
                                        :
                                        <>

                                            {resultados}
                                        </>
                                }
                            </>
                            :
                            <>
                            </>
                    }

                </div>

            </div>
        </seccion >
    )
}