import React from 'react'
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./styles.css";

const PremiosCard = ({
    tipo,
    anio,
    artista,
    titulo,
    donadoA,
    imagen,
    id
}) => {
  return (
   <Card className="ObrasCard">
    <Link
      className="ObrasCardLink"
      to={"/premios/" + id }
      onClick={() => {
        window.scroll(0, 0);
      }}
    >
      
      <img className="ObrasCardImg" alt="Sample" src={require(`../../assets/img/premios/${imagen}`)} />
      <div className="ObrasCardTextConteiner">
        <h2 className="ObrasCardTitulo"  id="nombre" style={{fontSize: 16}}>
          {tipo} - {anio}
        </h2>
        <h5 className="ObrasCardtype" style={{textAlign: "center"}} id="nombre">
          {titulo}
        </h5>
        <h5 className="ObrasCardtype" style={{textAlign: "center"}}>{artista}</h5>
        <h5 className="ObrasCardtype" style={{textAlign: "center"}}>{donadoA}</h5>
      </div>
    </Link>
  </Card>  
  )
}

export default PremiosCard
