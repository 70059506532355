import React from 'react';
import { Row, Col, Card } from 'react-bootstrap'; // Asegúrate de importar los componentes necesarios

const SkeletonCardInfo = () => {
  return (
    <Row>
      <Col xs="12" className="mb-md-4">
        <Card>
          <Card.Body>
            <div className="skeleton-image" style={{ height: '200px' }}></div>
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12">
        <Card>
          <Card.Body>
            <div className="skeleton-text" style={{ width: '70%', marginBottom: '10px' }}></div>
            <div className="skeleton-text" style={{ width: '90%' }}></div>
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12">
        <hr />
      </Col>
      <Col xs="12">
        <Card>
          <Card.Body>
            <div className="skeleton-button" style={{ width: '30%', height: '40px' }}></div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SkeletonCardInfo;
