import "./styles.css";
import { Card } from "react-bootstrap";
import bg from "./../../assets/img/Bg.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

export default function PublicacionCard({
  Titulo = "Titulo de publicacion",
  SubTitulo = "SubTitulo de la Publicacion",
  Categoria = "categoria",
  texto = "Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto",
  Img = bg,
  id = 0,
}) {
  const [homenaje, sethomenaje] = useState("");

  useEffect(() => {
    let parrafos = "";

    //ver si tiene un homenaje el texto.
    if (texto !== null) {
      parrafos = texto.split("<p>");
    }

    const parrafoFinal = [];

    for (let i = 1; i < parrafos.length; i++) {
      const parrafo = parrafos[i];
      if (parrafo.includes("HOMENAJE")) {
        parrafoFinal.push(parrafo);
      }
    }

    sethomenaje(parrafoFinal);
  }, []);

  return (
    <Card className="NoticiaCarta">
      <Link className="noticiaLink" to={"/publicaciones/publicacion?id=" + id}>
        <div className="NoticiaImageContainer">
          <img className="NoticiaCardImg" alt="Sample" src={Img} />
        </div>

        <div className="NoticiaTextConteiner">
          <p className="NoticiaCardTitulo">{Titulo}</p>
          <p className="NoticiaCardSubTitulo">{SubTitulo}</p>
          <div className="NoticiaCardDivider"></div>
          <p
            className="NoticiaCardTexto"
            dangerouslySetInnerHTML={{ __html: homenaje }}
          />
          <span className="NoticiaCardMas">Leer más</span>
        </div>
      </Link>
    </Card>
  );
}
