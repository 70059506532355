import "./assets/css/styles.css";
import "./assets/css/media-queries.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./routes/error-page";
import Root from "./routes/root";
import Home from "./screens/Home";
import Institucional from "./screens/Institucional";
import Academicos from "./screens/Academicos";
import AcademicosSingle from "./screens/AcademicosSingle";
import FondoDocumental from "./screens/FondoDocumental";
import FondoDocumentalSingle from "./screens/FondoDocumentalSingle";
import Publicaciones from "./screens/Publicaciones";
import Noticias from "./screens/Noticias";
import Hitos from "./screens/hitos";
import PublicacionSingle from "./screens/Publicaciones_Single";
import NoticiasSinlge from "./screens/Noticias_Single";
import NotFound from "./screens/NotFound";
import Estatuto from "./screens/estatuto";
import BuscadorHome from "./screens/buscador";
import Instituciones from "./screens/instituciones-relacionadas";
import Premios from "./screens/Premios";
import FundacionTrabucco from "./screens/FundacionTrabucco";
import PremiosSingle from "./screens/PremiosSingle";

export default function App() {
  const router = createBrowserRouter([
    {
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "sobre-nosotros",
          element: <Institucional />,
        },
        {
          path: "academicos",
          element: <Academicos />,
        },
        {
          path: "academicos/:id",
          element: <AcademicosSingle />,
        },
        {
          path: "fondo-patrimonial",
          element: <FondoDocumental />,
        },
        {
          path: "fondo-patrimonial/:id",
          element: <FondoDocumentalSingle />,
        },
        {
          path: "publicaciones",
          element: <Publicaciones />,
        },
        {
          path: "publicaciones/publicacion",
          element: <PublicacionSingle />,
        },
        {
          path: "noticias",
          element: <Noticias />,
        },
        {
          path: "noticias/:id",
          element: <NoticiasSinlge />,
        },
        {
          path: "/hitos",
          element: <Hitos />,
        },
        {
          path: "estatuto",
          element: <Estatuto />,
        },
        {
          path: "fundacion-trabucco",
          element: <FundacionTrabucco />,
        },
        {
          path: "buscador",
          element: <BuscadorHome />,
        },
        { path: "instituciones-relacionadas", element: <Instituciones /> },
        { path: "premios", element: <Premios /> },
        {
          path: "premios/:id",
          element: <PremiosSingle />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
