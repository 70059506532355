export default function OrdenarPublicaciones(a, b) {

    let nombre1 = a.props.orden
    let nombre2 = b.props.orden

    console.log(nombre1);
    console.log(nombre2);

    if (nombre1 < nombre2) {
        console.log("<")
        return -1;
    }
    if (nombre1 > nombre2) {
        console.log(">")
        return 1;
    }
    return 0;
}