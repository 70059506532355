import React from "react";
import './style.css';
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import bg from './../../assets/img/Bg.png'
export default function ResultadoBuscador(
    {
        Img = bg,
        titulo = "Titulo de la noticia",
        tipo = "Tipo Noticia",
        texto = "",
        id = '0',
        tipoCarta = true
    }
) {

    return (
        <>
            <Card className="BuscadorCard">
                {
                    tipoCarta ?
                        <>
                            <Link to={'/noticias/' + id + '?id=' + id} onClick={() => { window.scroll(0, 0); }}>
                                <img
                                    className="BuscadorCardImg"
                                    alt={titulo}
                                    src={Img}
                                />
                            </Link>
                        </>
                        :
                        <>
                            <Link to={'/academicos/' + id + '?id=' + id} onClick={() => { window.scroll(0, 0); }}>
                                <img
                                    className="BuscadorCardImg"
                                    alt={titulo}
                                    src={Img}
                                />
                            </Link>
                        </>
                }

                <div className="BuscadorCardTextConteiner">
                    <h1 className="BuscadorTitulo">{titulo}</h1>
                    <div className="BuscadorDivider"></div>
                    <p className="BuscadorTipo">{tipo}</p>

                    {
                        tipoCarta ?
                            <>
                                <Link className="BuscadorLink" to={'/noticias/' + id + '?id=' + id} onClick={() => { window.scroll(0, 0); }}>
                                    <span className="BuscadorMasInfo">
                                        Mas info
                                    </span>
                                </Link>
                            </>
                            :
                            <>
                                <Link to={'/academicos/' + id + '?id=' + id} onClick={() => { window.scroll(0, 0); }}>
                                    <span className="BuscadorMasInfo">
                                        Mas info
                                    </span>
                                </Link>
                            </>
                    }

                </div>
            </Card >
        </>
    )
}