import React, { useEffect, useState } from "react";
import "./style.css";
import { Container } from "react-bootstrap";
import bg from "./../../assets/img/Bg.png";
import { REST_URL } from "./../../config/env";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import "./../../assets/css/ckeditor.css";

export default function FondoDocumentalSingle() {
  const [fondoDocumental, setFondoDocumental] = useState();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    buscarDatosFondoDocumental();
  }, []);

  async function buscarDatosFondoDocumental() {
    const valores = new URLSearchParams(window.location.search);
    const id = valores.get("id");

    await fetch(REST_URL + "/fondoDocumental/" + id)
      .then((response) => response.json())
      .then((rawJson) => {
        console.log(rawJson[0]);
        setFondoDocumental(rawJson[0]);
        setloading(true);
      });
  }

  return (
    <section id="fondoDocumental-single">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/Libros.png")}
          alt="Sala de plenario"
        />
        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            Fondo Patrimonial Y COLECCION
          </h3>
          <p className="subTituloPortada">
            HOME /{" "}
            <span className="subTituloPortadaSpan">
              FONDO PATRIMONIAL Y COLECCION
            </span>
          </p>
        </div>
      </article>
      <div className="d-md-block">
        <Container className="ContainerFondoDocumentalSingle py-3">
          <div className="BodyFondoDocumental">
            {loading ? (
              <>
                <img
                  src={
                    "https://anba-admin.depcsuite.com/fondo_documental_imagenes/" +
                    fondoDocumental.imagen
                  }
                  alt={fondoDocumental.titulo}
                  className="fondoDocumentalImg"
                />
                <div
                  className="FondoDocumentalepigrafe ck-content"
                  dangerouslySetInnerHTML={{ __html: fondoDocumental.epigrafe }}
                />
                <h5 className="FondoDocumentalSubTitulo">FONDO PATRIMONIAL</h5>
                <div className="FondoDocumentalDivider"></div>
                <h3 className="FondoDocumentalTitulo">
                  {fondoDocumental.titulo}
                </h3>
                <div
                  className="FondoDocumentalDescripcion ck-content"
                  dangerouslySetInnerHTML={{
                    __html: fondoDocumental.descripcion,
                  }}
                />
                <div className="FondoDocumentalCompartir">
                  <p className="Compartir">COMPARTIR : </p>
                  <FacebookShareButton url={window.location.href}>
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      id="FondoDocumentalIconFacebook"
                    />
                  </FacebookShareButton>
                  <TwitterShareButton url={window.location.href}>
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="FondoDocumentalIcon"
                    />
                  </TwitterShareButton>
                </div>
              </>
            ) : (
              <>
                <img
                  src={bg}
                  alt="Cargando..."
                  className="fondoDocumentalImg"
                />
                <h5 className="FondoDocumentalSubTitulo">FONDO PATRIMONIAL</h5>
                <div className="FondoDocumentalDivider"></div>
                <h3 className="FondoDocumentalTitulo">CARGANDO...</h3>
                <p className="FondoDocumentalDescripcion">
                  Cargando descripcion...
                </p>
                <div className="FondoDocumentalCompartir">
                  <p className="Compartir">COMPARTIR : </p>
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    id="FondoDocumentalIconFacebook"
                  />
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="FondoDocumentalIcon"
                  />
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="FondoDocumentalIcon"
                  />
                </div>
              </>
            )}
          </div>

          <Link
            to="/fondo-patrimonial"
            className="d-none d-md-block LinkVolver"
          >
            <div className="VolverFondoDocumental">
              <div className="VolverContainer">
                <p className="VolverDocumentalYColeccion">
                  VOLVER A FONDO
                  <br />
                  PATRIMONIAL Y COLECCION
                </p>
              </div>
            </div>
          </Link>
        </Container>
      </div>
    </section>
  );
}
